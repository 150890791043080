import React from "react";
//libraries
import { Route, useHistory } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
// Utils
import settings from '../config';
import { inicio } from '../pathName';

const ActiveSessionWithCredit = ({ component: Component }) => {
  let history = useHistory();
  let userReducer = useSelector((state) => state.userReducer);

  console.log(userReducer[settings.token_access] === '' && userReducer.info_credit.id_credit === -1)

  return (
    <Route
      render={(props) =>
        userReducer[settings.token_access] !== "" && userReducer.info_credit.id_credit !== -1
          ? (<Component {...props} />)
          : ( history.replace(inicio))
      }
    />
  );
};

export default ActiveSessionWithCredit;
