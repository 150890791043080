import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "POST";
const SUCCESS_STATUS_CODE = 201;

function postApplyForCredit(dataToSend, token) {
  const endPoint = `/credits/api/credit/management/allied_company/`;
  let API_POST_APPLY_FOR_CREDIT = endPointAPI + endPoint;
  return APICallback(
    dataToSend,
    API_POST_APPLY_FOR_CREDIT,
    METHOD,
    SUCCESS_STATUS_CODE,
    true,
    token,
    false,
    '',
    false,
    '',
  );
}

export { postApplyForCredit };
