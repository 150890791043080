import { useState } from 'react'
//styles
import styles from "./modalLoginCellphone.module.css";
//libraries
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Modal from "@material-ui/core/Modal";
import { FaArrowLeft, FaTimes } from "react-icons/fa";
// redux
import { setInfoAuth } from "redux/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";
//Components
import TheButton from "components/TheButton/TheButton";
import Asterisco from "components/Asterisco/Asterisco";
import TheInput from "components/TheInput/TheInputFormik";
import ImageInfoLogin from "../components/imageInfoLogin/ImageInfoLogin";
// Api
import { postCellphoneLogin } from 'Api/login/postCellphoneLogin';
import { toast_custom } from 'components/toast_custom/toast_custom';

const ModalLoginCellphone = (props) => {
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  let userReducer = useSelector((state) => state.userReducer);

  // Sent OTP
  const signIn = (mynumber) => {
    setLoading(true)
    if (mynumber === "" || mynumber.length < 10) return;
    const numberToSend = `+57${mynumber}`;

    const dataToSend = {
      verify_user: true,
      cellphone: numberToSend
    }

    postCellphoneLogin(dataToSend)
      .then((response) => {
        setLoading(false)
        dispatch(
          setInfoAuth({
            ...userReducer.info_auth,
            cellphone: mynumber,
          })
        );
        const { data, errored } = response
        if (errored) {
          switch (data.err_code) {
            case "USER_NOT_FOUND":
              toast_custom(
                "No te encuentras registrado, para hacerlo solicita un crédito",
                "error",
                4000,
                "top-right"
              );
              break;
            default:
              toast_custom(
                "Upss, Estamos presentando problemas",
                "error",
                4000,
                "top-right"
              );
              break;
          }
          return
        }
        props.setModalLogin(false);
        props.setModalCodeLogin(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal
      open={props.isModalLogin}
      onClose={() => props.setModalLogin(!props.isModalLogin)}
    >
      <div className={styles.principal_container}>
        <Formik
          initialValues={{
            mynumber: "",
          }}
          validationSchema={Yup.object().shape({
            mynumber: Yup.number()
              .typeError("Ingresa un número válido")
              .test(
                "len",
                "El número de teléfono debe tener 10 díjitos",
                (val) => `${val}`.length === 10
              )
              .required("Ingresa un número de teléfono")
          })}
          onSubmit={(values) => signIn(values.mynumber)}
        >
          {(formik) => {
            const { values } = formik;
            return (
              <div className={styles.content_modal}>
                <FaArrowLeft
                  className={styles.arrow_icon}
                  onClick={() => props.setModalLogin(!props.isModalLogin)}
                />
                <div className={styles.section_info}>
                  <ImageInfoLogin image="images/components/coffe_cup_login.png" />
                </div>
                <div className={styles.form_login}>
                  <FaTimes
                    className={styles.close_icon}
                    onClick={() => props.setModalLogin(!props.isModalLogin)}
                  />
                  <div className={styles.container_info}>
                    <p className={styles.login_title}>Iniciar sesión</p>
                    <p className={styles.subtitle}>
                      Ingresa a tu cuenta en solo 2 simples <br /> pasos
                    </p>
                  </div>
                  <Form className={styles.form}>
                    <div className={styles.input}>
                      <p className={styles.label}>
                        Número de telefono
                        <Asterisco />
                      </p>
                      <TheInput
                        type="number"
                        name="mynumber"
                        format="### ### ####"
                        value={values.mynumber}
                        nameText="Ej. 317 510 9296"
                        onChange={formik.setFieldValue}
                      />
                      <div
                        style={{ marginTop: "1.3rem" }}
                        id="recaptcha-container"
                      ></div>
                      <div
                        style={{
                          width: "100%",
                          marginTop: "1rem",
                        }}
                      >
                        <TheButton
                          nameText="Ingresar"
                          isLoading={loading}
                          background={"var(--MEDIUM_BLUE)"}
                        />
                      </div>
                    </div>
                  </Form>
                  <div className={styles.footer_info}>
                    <p className={styles.have_password}>
                      ¿Ya tienes contraseña?
                    </p>
                    <p
                      onClick={() => {
                        props.setModalLogin(false);
                        props.setModalLoginEmail(true);
                      }}
                      className={styles.login_email_and_password}
                    >
                      Iniciar sesion con usuario y contraseña
                    </p>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default ModalLoginCellphone;
