import React, { useEffect } from "react";
// Components
import SliderFunction from "../../../../components/Slider/Slider";
// styles
import styles from "./LandingSliders.module.css";
// REDUX

export default function LandingSliders(props) {
  let { setFee, setAmount, fee, amount, noEntra } = props;
  let minAmount = 100000
  let maxAmount = 800000
  let minFee = 1
  let maxFee = 4

  useEffect(() => {
    setAmount(maxAmount / 2)
  }, [])

  return (
    <>
      <div className={styles.slider_money}>
        <SliderFunction
          money={true}
          step={50000}
          value={amount}
          min={minAmount}
          max={maxAmount}
          noEntra={noEntra}
          setvalue={setAmount}
          isModal={props.isModal}
          text="¿Cúanto dinero necesitas?"
          defaultValue={maxAmount / 2}
        />
      </div>

      <SliderFunction
        step={1}
        value={fee}
        min={minFee}
        max={maxFee}
        noEntra={noEntra}
        setvalue={setFee}
        defaultValue={minFee}
        isModal={props.isModal}
        text="¿A cúantas cúotas mensuales?"
      />
    </>
  );
}
