import React, { useState, useEffect } from "react";

import styles from "./ForgotPassword.module.css";
import { postChangePassword } from "Api/login/postChangePassword";
//libraries
import * as Yup from "yup";
import { Formik, Form, ErrorMessage } from "formik";
//Components
import TheInput from "components/TheInput/TheInput";
import Loader from '../../components/loader/Loader';
import TheButton from "components/TheButton/TheButton";
import { toast_custom } from "components/toast_custom/toast_custom";
// Utils
import { landing } from "../../utils/pathName";

function ForgotPassword(props) {
  const [token, set_token] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    let token_url = urlParams.get("token") ? urlParams.get("token") : "";
    set_token(token_url);
  }, []);

  const apiPostChangePassword = (password, password2) => {
    if (password !== password2) {
      toast_custom(
        "Las contraseñas no coinciden",
        "error",
        4000,
        "top-right"
      );
      return;
    }
    let dataToSend = {
      password,
      token,
    };
    setLoading(true)
    postChangePassword(dataToSend).then((response) => {
      setLoading(false)
      console.log("change password: ", response);
      let { errored, data, status } = response;
      if (errored) {
        if (status === 400) {
          toast_custom(
            "Debes solicitar nuevamente recuperar contraseña, este acceso ya caducó.",
            "error",
            4000,
            "top-right"
          );
        } else {
          toast_custom(
            "Upps, estamos presentando problemas",
            "error",
            4000,
            "top-right"
          );
        }
        return;
      } else {
        toast_custom(
          "La contraseña se ha cambiado correctamente",
          "success",
          4000,
          "top-right"
        );
        props.history.push(landing);
        return;
      }
    });
  };

  return (
    <div className={styles.container_recover_password}>
      <Loader loading={loading} />
      <div className={styles.form_passwords}>
        <img
          alt="Password"
          className={styles.icon_password}
          style={{ margin: "0 0 1.5rem 0" }}
          src="/images/components/recover_password.png"
        />
        <h3>Actualizar contraseña</h3>
        <Formik
          initialValues={{
            password: "",
            password2: "",
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string()
              .min(8, "La contraseña debe tener mínimo 8 caracteres")
              .matches(/[0-9]/, "La contraseña debe tener al menos un número")
              .matches(
                /[A-Z]/,
                "La contraseña debe tener al menos una mayúscula"
              )
              .required("La contraseña es requerida"),
            password2: Yup.string()
              .min(8, "La contraseña debe tener mínimo 8 caracteres")
              .matches(/[0-9]/, "La contraseña debe tener al menos un número")
              .matches(
                /[A-Z]/,
                "La contraseña debe tener al menos una mayúscula"
              )
              .required("La contraseña es requerida"),
          })}
          onSubmit={(values) => {
            apiPostChangePassword(values.password, values.password2);
          }}
        >
          {(formik) => {
            const { values } = formik;
            return (
              <Form className={styles.form}>
                <div className={styles.container_input}>
                  <h6 style={{ margin: "1rem 0" }}>
                    Ingresa la nueva contraseña para tu cuenta
                  </h6>
                  <TheInput
                    type="password"
                    name="password"
                    value={values.password}
                    onChange={formik.handleChange}
                    nameText="Ingresa la nueva contraseña"
                  />
                  <ErrorMessage
                    component="p"
                    name="password"
                    className={styles.label_error}
                  />
                </div>
                <div className={styles.container_input}>
                  <h6 style={{ margin: "1rem 0" }}>
                    Confirma la nueva contraseña para tu cuenta
                  </h6>
                  <TheInput
                    type="password"
                    name="password2"
                    value={values.password2}
                    onChange={formik.handleChange}
                    nameText="Confirma la nueva contraseña"
                  />
                  <ErrorMessage
                    component="p"
                    name="password2"
                    className={styles.label_error}
                  />
                </div>
                <div style={{ marginTop: "1rem", width: "100%" }}>
                  <TheButton
                    nameText="Cambiar contraseña"
                    background={"var(--MEDIUM_BLUE)"}
                  />
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default ForgotPassword;
