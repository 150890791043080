//styles
import styles from './TheDataPicker.module.css'
import "react-datepicker/dist/react-datepicker.css";
//libraries
import
DatePicker,
{ registerLocale }
  from "react-datepicker";
import es from "date-fns/locale/es";
import { ErrorMessage } from 'formik';

//se registra para tener la fecha en español
registerLocale("es", es);

export default function TheDatePicker(props) {
  const ReadonlyInput = ({ value, onClick }) => (
    <p
      onClick={onClick}
      className={styles.input_date}
      style={{ color: value ? "black" : "#999" }}
    >
      {value ? value : props.nameText}
    </p>
  );

  return (
    <div className={styles.container}>
      <DatePicker
        locale="es"
        showYearDropdown
        showMonthDropdown
        selected={props.date}
        dropdownMode="select"
        minDate={props.minDate}
        maxDate={props.maxDate}
        startDate={props.startDate}
        scrollableYearDropdown
        dateFormatCalendar="MMMM"
        dateFormat="dd/MM/yyyy"
        yearDropdownItemNumber={80}
        customInput={<ReadonlyInput />}
        withPortal={window.innerWidth < 600 ? true : false}
        onChange={date => props.onChange(props.name, date)}
      />
      <ErrorMessage className={styles.error} component="div" name={props.name} />
    </div>
  );
}