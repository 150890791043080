// Styles
import styles from "./ArrowBack.module.css";
// Libraries
import { FaArrowLeft } from "react-icons/fa";
// Images
import LogoCrediConvenio from "images/Credit/LogoGris.png";

export default function ArrowBack(props) {
  return (
    <>
      <h5 onClick={props.onClick} className={styles.back}>
        <FaArrowLeft style={{ marginRight: "1.5rem" }} />
        <p className={styles.textBack}>{props.backText}</p>
      </h5>
      {props.showLogo &&
        <div className={styles.logoContainer}>
          <img className={styles.Logo} src={LogoCrediConvenio} alt="Logo CrediConvenio" />
        </div>
      }
    </>
  );
}
