import React from "react";
//libraries
import { Route, useHistory } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
// Utils
import { landing } from '../pathName';

const SignUp = ({ component: Component }) => {
  let history = useHistory();
  let userReducer = useSelector((state) => state.userReducer);
  let userDataSignUp = useSelector((state) => state.userSignUpReducer);

  return (
    <Route
      render={(props) =>
        !userDataSignUp.cellphone || userDataSignUp.cellphone === "" ? (
          history.replace(landing)
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default SignUp;
