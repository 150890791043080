import { postAccessToken } from "Api/login/postAccessToken";
import { setInfoAuth } from "redux/Actions/Actions";
import { setInfoUser } from "../../redux/Actions/Actions";
// Utils
import settings from "../config";

export function apiRefreshToken(dispatch, userReducer, history, apiToRepeat) {
  let dataToSend = {
    refresh: userReducer[settings.token_refresh],
  };
  postAccessToken(dataToSend).then(async (response) => {
    let { errored, data } = response;
    if (errored) {
      dispatch(
        setInfoUser({
          ...userReducer.info_user,
          dni: "",
          photo: "",
          email: "",
          id_user: "",
          cellphone: 0,
          last_name: "",
          genre_key: "",
          first_name: "",
          genre_label: "",
          type_document: "",
          type_document_label: "",
        })
      );
      dispatch(
        setInfoAuth({
          token_access: "",
          token_refresh: "",
          ...userReducer.info_auth,
          cellphone: 0,
        })
      );
    } else {
      dispatch(
        setInfoAuth({
          token_access: data.access,
          token_refresh: userReducer[settings.token_refresh],
          ...userReducer.info_auth,
        })
      );
      if (apiToRepeat) apiToRepeat(data.access);
    }
  });
}
