import { useState, useEffect } from 'react';
//styles
import styles from './title.module.css';
//libraries
import { 
  FaArrowLeft
 } from "react-icons/fa";

const Title = props => {
  const [windowWidth, setWindowHeight] = useState(window.screen.width);

  useEffect(() => {
    function updateSize() {
      setWindowHeight(window.innerWidth)
    }    
    window.addEventListener('resize', updateSize);
    return () => window.removeEventListener('resize', updateSize);
  }, [])  

  return (
    <div className={styles.container_title} onClick={props.click}>
      {windowWidth > 768 && (
        <FaArrowLeft className={styles.icon} />
      )}
      <p className={styles.title}>{props.title}</p>
    </div>
  )
}

export default Title;