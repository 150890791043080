import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "POST";
const SUCCESS_STATUS_CODE = 200;

function postChangePassword(dataToSend) {
  const endPoint = `/users/api/forgot/password/`;
  let API_POST_CHANGE_PASSWORD = endPointAPI + endPoint;
  return APICallback(
    dataToSend,
    API_POST_CHANGE_PASSWORD,
    METHOD,
    SUCCESS_STATUS_CODE,
    false,
    '',
    false,
    '',
    false,
    '',
  );
}

export { postChangePassword };
