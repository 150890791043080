import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./ModalLanding.module.css";
import { useHistory } from "react-router";
// images
import documentoEnMano from "../../../../../images/modal/modal_ingresar_numero.png";
import verificarIdentidad from "../../../../../images/modal/modal_verificar_identidad.png";
// Components
import Asterisco from "components/Asterisco/Asterisco";
import TheInput from "components/TheInput/TheInputFormik";
import TheButton from "../../../../../components/TheButton/TheButton";
import { toast_custom } from "../../../../../components/toast_custom/toast_custom";
// utils
import { inicio, personalInfo } from "utils/pathName";
// libraries
import * as Yup from "yup";
import { Formik, Form } from "formik";
// redux
import { setInfoAuth, setInfoUser } from "redux/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";
import { setDataUserSignUp } from "redux/Actions/ActionsSignUp";
// API
import { postCellphoneLogin } from "Api/login/postCellphoneLogin";
import { postCodeLogin } from "Api/login/postCodeLogin";

export default function ModalLanding() {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [vOpen, vSetOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  let dispatch = useDispatch();
  let userReducer = useSelector((state) => state.userReducer);
  let userDataSignUp = useSelector((state) => state.userSignUpReducer);

  const handleOpenV = () => {
    setOpen(!open);
    vSetOpen(!vOpen);
    setLoading(false)
  };
  const handleCloseV = () => {
    setOpen(!open);
    vSetOpen(!vOpen);
    setLoading(false)
  };

  // Sent OTP
  const signin = (mynumber) => {
    if (mynumber === "" || mynumber.length < 10) return;
    const numberToSend = `+57${mynumber}`;
    const dataToSend = {
      cellphone: numberToSend,
      login_type: "crediconvenio",
    }
    setLoading(true)
    postCellphoneLogin(dataToSend)
      .then((response) => {
        setLoading(false)
        dispatch(
          setInfoAuth({
            ...userReducer.info_auth,
            cellphone: mynumber,
          })
        );
        setOpen(!open);
        vSetOpen(!vOpen);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const apiPostCodeVerification = (code) => {
    const cellphoneToSend = `+57${userReducer.info_auth.cellphone}`;
    if (code === null) return;
    const dataToSend = {
      otp_code: code,
      cellphone: cellphoneToSend,
    };
    setLoading(true);
    postCodeLogin(dataToSend)
      .then((response) => {
        setLoading(false);
        console.log(`response code verification`, response);
        let { data, errored } = response;
        if (errored) {
          switch (data.err_code) {
            case "USER_CELPHONE_EXISTS":
              toast_custom(
                "Número de teléfono ya se encuentra registrado, puedes iniciar sesión",
                "warning",
                4000,
                "top-right"
              );
              break;
            default:
              toast_custom(
                "Upss, Estamos presentando problemas",
                "error",
                4000,
                "top-right"
              );
              break;
          }
        } else {
          if (data.data_login) {
            dispatch(
              setInfoUser({
                ...userReducer.info_user,
                dni: data.data_login.user_data.dni,
                photo: data.data_login.user_data.photo,
                email: data.data_login.user_data.email,
                id_user: data.data_login.user_data.id_user,
                last_name: data.data_login.user_data.last_name,
                cellphone: +data.data_login.user_data.cellphone,
                genre_key: data.data_login.user_data.genre_key,
                first_name: data.data_login.user_data.first_name,
                genre_label: data.data_login.user_data.genre_label,
                type_document: data.data_login.user_data.type_document,
                type_document_label:
                  data.data_login.user_data.type_document_label,
              })
            );
            dispatch(
              setInfoAuth({
                token_access: data.data_login.tokens.access,
                token_refresh: data.data_login.tokens.refresh,
                ...userReducer.info_auth,
                cellphone: +data.data_login.user_data.cellphone,
              })
            );
            history.push(inicio);
          } else {
            // actualizar el teléfono en el registro
            dispatch(
              setDataUserSignUp({
                ...userDataSignUp,
                cellphone: userReducer.info_auth.cellphone,
              })
            )
            history.push(personalInfo);
          }
        }
      })
      .catch((err) => {
        toast_custom(
          "Upps, Estamos presentando problemas",
          "error",
          4000,
          "top-right"
        );
      });
  };

  const MobileNumberModal = (
    <div className={styles.alignModal}>
      <div className={styles.modalContainer}>
        <img
          src={documentoEnMano}
          className={styles.modalIMG}
          alt="Número de teléfono para registro"
        />

        <h2 className={styles.colorText}>¡Hola!</h2>
        <p className={styles.pText}>
          Para empezar con el estudio de crédito es necesario que ingreses tu
          número de teléfono para enviarte un mensaje de texto (SMS) un{" "}
          <strong style={{ fontFamily: "var(--FONT_MEDIUM)" }}>
            Código de verificación
          </strong>
        </p>

        <div className={styles.container_submit}>
          <h2 className={styles.colorTextPhone}>
            Número de teléfono
            <Asterisco />
          </h2>
          <Formik
            initialValues={{
              mynumber: "",
            }}
            validationSchema={Yup.object().shape({
              mynumber: Yup.number()
                .typeError("Ingresa un número válido")
                .test(
                  "len",
                  "El número de teléfono debe tener 10 díjitos",
                  (val) => `${val}`.length === 10
                )
                .required("Ingresa un número de teléfono"),
            })}
            onSubmit={(values) => {
              if (!loading) {
                signin(values.mynumber)
              }
            }
            }
          >
            {(formik) => {
              const { values } = formik;
              return (
                <Form>
                  <TheInput
                    type="number"
                    name="mynumber"
                    format={"### ### ####"}
                    value={values.mynumber}
                    nameText="Ej. 317 510 9296"
                    onChange={formik.setFieldValue}
                  />
                  <div style={{ marginTop: "1rem" }}>
                    <TheButton
                      isLoading={loading}
                      nameText="Siguiente"
                      background="var(--MEDIUM_BLUE)"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );

  const VerificationModal = (
    <div className={styles.alignModal}>
      <div className={styles.modalContainer}>
        <img
          src={verificarIdentidad}
          className={styles.modalIMG}
          alt="Verificando identidad"
        />

        <h2 className={styles.colorText}>¡Felicidades!</h2>
        <div className={styles.textContainerCrediConvenio}>
          <h2 className={styles.colorTextVerificacion2}>Ya eres parte de</h2>
          <h2 className={styles.CrediConvenioText}>CrediConvenio</h2>
        </div>
        <p className={styles.pText}>
          Y te acabamos de enviar un mensaje de texto (SMS) al número de
          teléfono que nos diste para finalizar tu registro
        </p>

        <div className={styles.container_submit}>
          <Formik
            initialValues={{
              Code: "",
            }}
            validationSchema={Yup.object().shape({
              Code: Yup.string().test(
                "len",
                "El código debe tener 6 números",
                (val) => `${val}`.length === 6
              ),
            })}
            onSubmit={(values) => { if (!loading) apiPostCodeVerification(values.Code) }}
          >
            {(formik) => {
              const { values } = formik;
              return (
                <Form>
                  <h2 className={styles.colorTextPhone}>
                    Código de verificación
                  </h2>
                  <TheInput
                    name="Code"
                    type="number"
                    format="# # # # # #"
                    value={values.Code}
                    nameText="Ingresa el código aquí"
                    onChange={formik.setFieldValue}
                  />
                  <div style={{ marginTop: "1rem" }}>
                    <TheButton
                      isLoading={loading}
                      nameText="Siguiente"
                      background="var(--MEDIUM_BLUE)"
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>

        <div className={styles.MessageAgain}>
          <p className={styles.arribaSolicitar}>
            ¿No te ha llegado el código aún?{" "}
          </p>
          <h2
            onClick={() => handleOpenV()}
            className={styles.SolicitarNuevamente}
          >
            Solicitar nuevamente
          </h2>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <TheButton
        background="var(--MEDIUM_BLUE)"
        nameText="Solicitar mi crédito"
        GoClick={() => setOpen(true)}
      />

      <Modal centered show={open} onHide={() => { setOpen(false); setLoading(false) }}>
        {MobileNumberModal}
      </Modal>

      <Modal centered show={vOpen} onHide={handleCloseV}>
        {VerificationModal}
      </Modal>

    </div>
  );
}
