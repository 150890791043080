// Styles
import styles from "./ViewDefault.module.css";
// images
import imageDefault from "../../../../images/home/credito_default.png";
// components
import TheButton from "../../../../components/TheButton/TheButton";
// Utils
import { reducedDateFormat, dateFormater } from "utils/dateFormater";

export default function ViewDefault(props) {
  let { nextDateToConsult, validate } = props
  return (
    <div className={styles.view}>
      <div className={styles.container_image}>
        <img src={imageDefault} alt="Sin crédito" />
      </div>
      <p className={styles.text} style={{ margin: "15px 0 0 0" }}>¡Vaya!</p>
      <p className={styles.text} style={{ margin: "0 0 15px 0" }}>
        {validate ? `Tenemos un crédito en el aire listo` : "¡Lo sentimos!"}
      </p>
      <p className={styles.text_intro}>
        {!validate ? `En este momento y hasta el ${dateFormater(nextDateToConsult)} no podras solicitar un nuevo credito` : "Justo ahora puedes solicitarlo de manera facíl y rápida"}
      </p>
      <div className={styles.container_button}>
        <TheButton
          isAvailable={!validate}
          opacity={!validate ? .4 : 1}
          background="var(--MEDIUM_BLUE)"
          nameText="Solicitar nuevo crédito"
          GoClick={() => props.setIsModalCreditRequest(true)}
        />
      </div>
    </div>
  );
}
