import { useEffect } from "react";
import "./App.css";
// Components
import Bubble from './components/Bubble/Bubble';
//libraries
import { useHistory } from "react-router";
import { FaWhatsapp } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import { BrowserRouter, Switch, Route } from "react-router-dom";
// Views
import Home from "./views/Home/Home";
import Landing from "./views/Landing/Landing";
import NotFound from "./views/notFound/notFound";
import Credit_History from "views/Credit/History/History";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import Credit_Approved from "views/Credit/viewApproved/Credit_Approved";
import Credit_Disbursement from "views/Credit/Disbursement/Credit_Disbursement";
import ConfirmCreditToUser from "views/ConfirmCreditToUser/ConfirmCreditToUser";
import Information_Data from "./views/Credit/Information/information_data/Information_data";
import Information_work from "./views/Credit/Information/information_work/Information_work";
import Information_validation from "./views/Credit/Information/information_validate/Information_validation";
// privates routes
import SignUp from "./utils/privateRoutes/signUp";
import ActiveSession from "./utils/privateRoutes/activeSession";
import ActiveSessionWithCredit from "./utils/privateRoutes/activeSessionWithCredit";
// Utils
import { apiRefreshToken } from "utils/Hooks/useApiRefreshToken";
import {
  landing,
  inicio,
  infoWork,
  newCredit,
  personalInfo,
  disbursement,
  dataValidate,
  creditHistory,
  creditApproved,
  forgotPassword,
  creditRetrenchment,
  confirmCreditToUser,
} from "./utils/pathName";
// Redux
import { useDispatch, useSelector } from "react-redux";

function App() {
  let history = useHistory()
  let dispatch = useDispatch();
  let userReducer = useSelector((state) => state.userReducer);

  useEffect(() => {
    const interval = setInterval(() => {
      apiRefreshToken(dispatch, userReducer, history)
      // console.log('interval')
    }, 3300000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <Bubble icon={FaWhatsapp} GoClick={() => console.log('what')} />
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path={landing}
            render={(props) => <Landing {...props} />}
          />
          <Route exact path={forgotPassword} component={ForgotPassword} />
          <Route exact path={confirmCreditToUser} component={ConfirmCreditToUser} />

          {/* private route */}
          <Route exact path={infoWork} component={Information_work} />
          <SignUp exact path={personalInfo} component={Information_Data} />
          <SignUp
            exact
            path={dataValidate}
            component={Information_validation}
          />

          <ActiveSessionWithCredit exact path={disbursement} component={Credit_Disbursement} />
          <ActiveSession exact path={inicio} component={Home} />
          <ActiveSession
            exact
            path={creditHistory}
            component={Credit_History}
          />
          <ActiveSession
            exact
            path={newCredit}
            component={Information_validation}
          />
          <ActiveSession
            exact
            path={creditRetrenchment}
            component={Information_validation}
          />
          <ActiveSessionWithCredit exact path={creditApproved} component={Credit_Approved} />
          {/* Default */}
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
