import React, { useState, useEffect } from "react";
//styles
import styles from "./TabPanelBank.module.css";
//components
import TheSelect from "components/TheSelect/TheSelect";
import TheButton from "components/TheButton/TheButton";
import TheInputFormik from "components/TheInput/TheInputFormik";
import { toast_custom } from "components/toast_custom/toast_custom";
// REDUX
import { setBanks } from "redux/Actions/BanksActions";
import { useDispatch, useSelector } from "react-redux";
import { setStates } from "redux/Actions/geographyActions";
// Utils
import settings from "../../../../../utils/config";
// Libraries
import * as Yup from "yup";
import { Formik, Form } from "formik";

export default function TabPanelBank(props) {
  let dispatch = useDispatch();
  let user = useSelector((state) => state.userReducer);
  let banks = useSelector((state) => state.banksReducer);
  let geography = useSelector((state) => state.geographyReducer);

  const [listCities, setListCities] = useState([]);
  const [bankSelected, setBankSelected] = useState({});
  const [citySelected, setCitySelected] = useState({});
  const [typeDeposit, setTypeDeposit] = useState("bank");
  const [typeCountBank, setTypeCountBank] = useState("savings");
  const [departmentSelected, setDepartmentSelected] = useState({});

  useEffect(() => {
    // if (JSON.stringify(departmentSelected) === "{}") {
    //   dispatch(setStates(66, setDepartmentSelected, setCitySelected, true));
    dispatch(setBanks(user[settings.token_access]));
    // }
  }, []);

  // useEffect(() => {
  //   if (JSON.stringify(departmentSelected) !== "{}") {
  //     dispatch(
  //       setStates(
  //         +departmentSelected.value,
  //         setDepartmentSelected,
  //         setCitySelected,
  //         setListCities
  //       )
  //     );
  //   }
  // }, [departmentSelected]);

  const validate = Yup.object().shape({
    account_number: Yup.string()
      .max(20, "Debe tener hasta 20 caracteres o menos")
      .required("Requerido"),
  });

  function validateDataToAPi(account_number) {
    if (typeDeposit === "bank" && JSON.stringify(bankSelected) === "{}") {
      toast_custom(
        "No has escogido el banco de tu cuenta",
        "error",
        4000,
        "top-right"
      );
      return
    }

    let dataToSend = {
      id_credit: user.info_credit.id_credit,
      disbursement_type: typeDeposit === "bank" ? "TRANSFER" : "MONEY_ORDER",
    };

    if (typeDeposit === "bank") {
      dataToSend.id_bank = bankSelected.value;
      dataToSend.account_number = account_number;
      dataToSend.account_type =
        typeCountBank === "savings" ? "SAVINGS" : "CHECKING";
    } /* else {
      dataToSend.code_city = citySelected.value;
    } */

    console.log("dataToSend", dataToSend);
    props.apiPostDisbursement(dataToSend)
  }

  return (
    <div className={styles.containerTabComplete}>
      {/* <p className={styles.title}>¿Cómo quieres recibir tu crédito?</p> */}
      {/* <div className={styles.containerControlTab}>
        <div
          style={{ borderRadius: "5px 0 0 5px" }}
          className={
            typeDeposit === "bank" ? styles.tabActive : styles.tabInactive
          }
          onClick={() => setTypeDeposit("bank")}
        >
          Cuenta bancaria
        </div>
        <div
          style={{ borderRadius: "0 5px 5px 0" }}
          className={
            typeDeposit === "turn" ? styles.tabActive : styles.tabInactive
          }
          onClick={() => setTypeDeposit("turn")}
        >
          Pago baloto
        </div>
      </div> */}
      <Formik
        initialValues={{
          account_number: "",
        }}
        validationSchema={typeDeposit === "bank" && validate}
        onSubmit={(values) => {
          validateDataToAPi(values.account_number);
        }}
      >
        {(formik) => {
          return (
            <Form className={styles.contentTab}>
              {typeDeposit === "bank" ? (
                <div className={styles.containerInput}>
                  <label>
                    ¿En qué banco tienes tu cúenta?
                    <TheSelect
                      options={banks.banks}
                      checkedOption={bankSelected.label}
                      setCheckedOption={setBankSelected}
                      nameText="Selecciona el banco de tu cúenta"
                    />
                  </label>

                  <div>
                    <p className={styles.title}>¿Qué tipo de cúenta manejas?</p>
                    <div className={styles.containerControlTab}>
                      <div
                        style={{ borderRadius: "5px 0 0 5px" }}
                        className={
                          typeCountBank === "savings"
                            ? styles.tabActive
                            : styles.tabInactive
                        }
                        onClick={() => setTypeCountBank("savings")}
                      >
                        Ahorros
                      </div>
                      <div
                        style={{ borderRadius: "0 5px 5px 0" }}
                        className={
                          typeCountBank === "current"
                            ? styles.tabActive
                            : styles.tabInactive
                        }
                        onClick={() => setTypeCountBank("current")}
                      >
                        Corriente
                      </div>
                    </div>
                  </div>
                  <label>
                    ¿Número de la cúenta?
                    <TheInputFormik
                      type="number"
                      name="account_number"
                      nameText="Ej: 901 223 225-45"
                      onChange={formik.setFieldValue}
                      value={formik.values.account_number}
                    />
                  </label>

                  <div className={styles.note}>
                    <strong>NOTA</strong>
                    <p>La cúenta debe estar a nombre del titular del crédito</p>
                  </div>
                </div>
              ) : (
                <div className={styles.containerInput}>
                  {/* <label>
                    ¿En que ciudad te encuentras?
                    <div>
                      <TheSelect
                        options={geography.states}
                        nameText="Selecciona un departamento"
                        checkedOption={departmentSelected.label}
                        setCheckedOption={setDepartmentSelected}
                      />
                    </div>
                    <div>
                      <TheSelect
                        options={geography.cities}
                        nameText="Selecciona una ciudad"
                        checkedOption={citySelected.label}
                        setCheckedOption={setCitySelected}
                      />
                    </div>
                  </label> */}
                </div>
              )}
              <TheButton
                nameText="Confirmar"
                // GoClick={() => setOpen(true)}
                background={"var(--MEDIUM_BLUE)"}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
