import styles from './notFound.module.css';
//components
import { useHistory } from 'react-router-dom';
//utils
import logo from 'images/LogoConvenios.png';

const NotFound = () => {
  let history = useHistory();

  return (
    <div className={styles.principal_container}>
      <div className={styles.header}>
        <img
          src={logo}
          alt="CrediConvenio"
          className={styles.logo}
        />
      </div>
      <div className={styles.content}>
        <img
          alt="404"
          src="/images/components/404.png"
          className={styles.not_found}
        />
        <p className={styles.title}>¿Tienes idea de que planeta estabas buscando?</p>
        <p className={styles.subtitle}>Honestamente... nosotros tampoco</p>
        <p className={styles.text}>
          Pero podemos llevarte de regreso a casa con solo un click
        </p>
        <button
          className={styles.btn}
          onClick={() => history.replace('/')}
        >
          Volver al home
        </button>
      </div>
    </div>
  )
}

export default NotFound;