export let landing = '/',
  inicio = '/inicio',
  newCredit = '/prestamo',
  infoWork = '/info-laboral',
  creditHistory = '/historial',
  personalInfo = '/info-basica',
  dataValidate = '/confirmar-info',
  creditRetrenchment = '/retanqueo',
  disbursement = '/finalizar-proceso',
  creditApproved = '/credito-aprobado',
  forgotPassword = '/recuperar-contrasena',
  confirmCreditToUser = '/confirmar/:id_credit'