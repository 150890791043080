// Paths
import { creditApproved, disbursement, creditRetrenchment } from "utils/pathName";
// styles
import styles from "./InfoCredito.module.css";
// utils
import { addComma } from 'utils/utils';
// Redux
import { useSelector } from "react-redux";

export default function InfoCredito() {
  let path = window.location.pathname
  let user = useSelector((state) => state.userReducer);

  return (
    <div className={styles.InfoContainer}>
      <div className={styles.info}>
        <p className={styles.label}>{
          window.location.pathname === creditApproved || window.location.pathname === disbursement
            ? 'Monto del crédito aprobado' 
            : 'Monto del crédito solicitado'
        }</p>
        <p className={styles.value}>${addComma(user.info_credit.amount)}</p>
      </div>
      <div className={styles.info}>
        <p className={styles.label}>Nro. de cúotas</p>
        <p className={styles.value}>{user.info_credit.fee}</p>
      </div>
      <div className={styles.info}>
        <p className={styles.label}>Valor de la cúota</p>
        <p className={styles.value}>${addComma(user.info_credit.monthlyCost)}</p>
      </div>
      <div className={styles.info}>
        <p className={styles.label}>Tasa de interes (%)</p>
        <p className={styles.value}>{user.info_credit.interest_rate * 100}%</p>
      </div>
      {path === creditRetrenchment && (
        <div className={styles.MontoYdesembolso}>
          <div className={styles.info}>
            <p className={styles.label}>Saldo restante</p>
            <p className={styles.remaining_value}>$ 100.000</p>
          </div>
          <div className={styles.info}>
            <p className={styles.label}>Monto a desembolsar</p>
            <p className={styles.disburse_value}>$ 650.000</p>
          </div>
        </div>
      )}
    </div>
  );
}
