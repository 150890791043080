import React, { useState } from "react";
//styles
import styles from "./Credit_Disbursement.module.css";
//components
import Infodisbur from "./components/info/infodisbur";
import Logodisbur from "./components/logo/logodisbur";
import Loader from '../../../components/loader/Loader';
import TabPanelBank from "./components/TabPanelBank/TabPanelBank";
import InfoCredito from "../../../components/InfoCredito/InfoCredito";
import ModalValidate from "./components/Modal/ModalValidate/ModalValidate";
// Libraries
import { useHistory } from "react-router";
// Redux
import { useSelector, useDispatch } from "react-redux";
// API
import { postDisbursement } from "Api/disbursemement/disbursement";
import { toast_custom } from 'components/toast_custom/toast_custom';
// Utils
import settings from '../../../utils/config';
import { apiRefreshToken } from "utils/Hooks/useApiRefreshToken";

export default function Credit_Disbursement() {
  let history = useHistory()
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  let dispatch = useDispatch();
  let userReducer = useSelector((state) => state.userReducer);

  const apiPostDisbursement = (dataToSend) => {
    setLoading(true);
    let token = userReducer[settings.token_access];
    postDisbursement(dataToSend, token).then((response) => {
      setLoading(false);
      console.log(`response disbursemet`, response);
      let { data, errored } = response;
      if (errored) {
        switch (data.err_code || data.code) {
          case "OBJECT_NOT_FOUND":
            toast_custom(
              "Credito no encontrado",
              "error",
              4000,
              "top-right"
            );
            break;
            case "token_not_valid":
              apiRefreshToken(dispatch, userReducer, history);
              // apiPostDisbursement()
            break;
          default:
            toast_custom(
              "Upss, Estamos presentando problemas",
              "error",
              4000,
              "top-right"
            );
            break;
        }
      } else {
        setOpen(true)
      }
    });
  };

  return (
    <>
      <Loader loading={loading} />
      <ModalValidate open={open} setOpen={setOpen} />
      <Logodisbur />
      <div className={styles.container_info_credit}>
        <Infodisbur />
        <InfoCredito />
      </div>
      <div className={styles.content}>
        <TabPanelBank
          setOpen={setOpen}
          apiPostDisbursement={apiPostDisbursement}
        />
      </div>
    </>
  );
}
