import { ipmt } from 'financial'

export default function simulatorCredit(
  fee,
  VAT,
  endorsement,
  centralRisk,
  interestsRate,
  requestedAmount,
  listValuesCreditStudyPerMonth = { 1: 0.12, 2: 0.19, 3: 0.24, 4: 0.24, 5: 0.24 },
  isAlliedCompany = false
) {

  console.log(fee,
    VAT,
    endorsement,
    centralRisk,
    interestsRate,
    requestedAmount,
    listValuesCreditStudyPerMonth,
    isAlliedCompany)
  let total, monthlyCost, valueCreditStudy, VATCreditStudy, valueEndorsement, interestPaid = 0;

  let listInterestsPerMonth = [];
  let n = 1;
  while (n <= fee) {
    let interest = Math.round(ipmt(interestsRate, n, fee, requestedAmount) * -1)
    listInterestsPerMonth.push(interest)
    interestPaid += interest
    n++;
  }

  if (isAlliedCompany) {
    valueCreditStudy = listValuesCreditStudyPerMonth[fee];
    // ---------------------------------------------------------------------
    //Validation Exception for allied company
    if (fee == 4 && requestedAmount <= 400_000) {
      valueCreditStudy = listValuesCreditStudyPerMonth[fee - 1]
    }
    if (fee == 4 && requestedAmount >= 600_000) {
      endorsement = 0.03
    }
    centralRisk = 0
    //---------------------------------------------------------------------
  } else {
    let percentageCreditStudy = listValuesCreditStudyPerMonth[fee];
    valueCreditStudy = Math.round(requestedAmount * percentageCreditStudy)

  }

  VATCreditStudy = Math.round(valueCreditStudy * VAT)
  let valueEndorsementBase = Math.round(requestedAmount * endorsement)
  valueEndorsement = valueEndorsementBase + Math.round(valueEndorsementBase * VAT)

  total = Math.round(requestedAmount + interestPaid + valueEndorsement + valueCreditStudy + VATCreditStudy + centralRisk)
  monthlyCost = Math.round(total / fee)

  return {
    total,
    centralRisk,
    monthlyCost,
    interestPaid,
    VATCreditStudy,
    requestedAmount,
    valueCreditStudy,
    valueEndorsement,
  };
}
