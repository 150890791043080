import React from "react";
import styles from "./Independiente.module.css";
import SearchBar from "components/SearchBar/SearchBar";

export default function Independiente(props) {
  let { idJobSelected, setIdJobSelected } = props

  return (
    <div className={styles.Container}>
      <br />
      <b>¿Que actividad desempeñas?</b>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <SearchBar
          placeholder="Buscar"
          idJobSelected={idJobSelected}
          setIdJobSelected={setIdJobSelected}
        />
      </div>
    </div>
  );
}
