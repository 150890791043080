import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "POST";
const SUCCESS_STATUS_CODE = 200;

function postEmailLogin(dataToSend, password) {
  const endPoint = `/users/api/login/classic/`;
  let API_POST_EMAIL_LOGIN = endPointAPI + endPoint;
  return APICallback(
    dataToSend,
    API_POST_EMAIL_LOGIN,
    METHOD,
    SUCCESS_STATUS_CODE,
    false,
    '',
    false,
    '',
    true,
    password,
  );
}

export { postEmailLogin };
