import React, { useState, useEffect } from "react";
//styles
import styles from "./ModalCreditRequest.module.css";
//libraries
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { setInfoCredit } from "redux/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";
// icons
import { FaTimes } from "react-icons/fa";
// Components
import TheButton from "../TheButton/TheButton";
import Resume from "../../views/Landing/Components/resume/Resume";
import LandingSliders from "../../views/Landing/Components/landingSliders/LandingSliders";
// Utils
import { newCredit } from "utils/pathName";
import simulatorCredit from "../../utils/creditSimulator";

export default function ModalCreditRequest(props) {
  let dispatch = useDispatch();
  const history = useHistory();
  const [fee, setFee] = useState(1);
  const [amount, setAmount] = useState(500000);
  const [dataSimulatorCredit, setDataSimulatorCredit] = useState({});
  let userReducer = useSelector((state) => state.userReducer);

  useEffect(() => {
    let responseSimulatorCredit = simulatorCredit(
      fee,
      parseFloat(userReducer.info_credit.vat),
      parseFloat(userReducer.info_credit.endorsement),
      parseFloat(userReducer.info_credit.central_risk),
      parseFloat(userReducer.info_credit.interest_rate),
      amount
    );
    setDataSimulatorCredit(responseSimulatorCredit);
    dispatch(
      setInfoCredit({
        ...userReducer.info_credit,
        fee,
        amount,
        monthlyCost: responseSimulatorCredit.monthlyCost,
      })
    );
  }, [fee, amount]);

  return (
    <Modal
      centered
      size="lg"
      show={props.isModalCreditRequest}
      onHide={() => props.setIsModalCreditRequest(!props.isModalCreditRequest)}
    >
      <Modal.Body className={styles.modal_body}>
        <p className={styles.title}>Solicitar nuevo crédito</p>
        <div className={styles.body}>
          <div className={styles.close}>
            <FaTimes
              onClick={() =>
                props.setIsModalCreditRequest(!props.isModalCreditRequest)
              }
            />
          </div>
          <div className={styles.simulator}>
            <LandingSliders
              fee={fee}
              noEntra={1}
              isModal={true}
              setFee={setFee}
              amount={amount}
              setAmount={setAmount}
            />

            <div className={styles.container_button}>
              <TheButton
                nameText="Solicitar crédito"
                background="var(--MEDIUM_BLUE)"
                GoClick={() => history.push(newCredit)}
              />
            </div>
          </div>

          <div className={styles.resume}>
            <Resume
              isModal={true}
              background="#f3F3F3"
              dataSimulatorCredit={dataSimulatorCredit}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
