import { set_geography, set_cities, set_countries } from "./ActionsName";
//Utils
import { getState } from 'Api/geography/getState';
import { getCities } from 'Api/geography/getCities';
import { getCountries } from 'Api/geography/getCountries';
import { toast_custom } from "components/toast_custom/toast_custom";

export function setCountries(setCountrySelected) {
  return async (dispatch) => {
    await getCountries().then(response => {
      // console.log("getCountries: ", response)
      let { data, errored } = response;
      if(errored) {
        toast_custom(
          "Upss, Estamos presentando problemas",
          "error",
          4000,
          "top-right"
        );
      } else {
        let { departments } = data;
        let list_countries = [];
        departments.forEach(country => {
          list_countries.push({
            label: country.label,
            value: country.code
          })
        })
        setCountrySelected(departments[41])
        dispatch({ type: set_countries, countries: list_countries });
      }
    })
  };
}

export function setStates(code_departament, setDepartment, setCitySelected, setListCities, loop) {
  return async (dispatch) => {
    await getState().then(response => {
      // console.log("getState: ", response)
      let { data, errored } = response;
      if(errored) {
        toast_custom(
          "Upss, Estamos presentando problemas",
          "error",
          4000,
          "top-right"
        );
      } else {
        let { departments } = data;
        let states = [];
        departments.forEach(departament => {
          states.push({
            label: departament.label,
            value: departament.code
          })
        })
        dispatch({ type: set_geography, states });
        if(loop){
          setDepartment(states[26])
        }
        dispatch(setCities(code_departament, setCitySelected, setListCities));
      }
    })
  };
}

export function setCities(code_departament, setCitySelected, setListCities) {
  return async (dispatch) => {
    let dataToSend = {
      code_departament
    }
    await getCities(dataToSend).then(response => {
      // console.log("getCities: ", response)
      let { data, errored } = response;
      if(errored) {
        toast_custom(
          "Upss, Estamos presentando problemas",
          "error",
          4000,
          "top-right"
        );
      } else {
        let { cities } = data;
        let listCities = [];
        cities.forEach(city => {
          listCities.push({
            label: city.label,
            value: city.code
          })
        })
        dispatch({ type: set_cities, cities: listCities });
        setCitySelected(listCities[0])
        setListCities(listCities)
      }
    })
  };
}

