import styles from './Account.module.css'
// Libraries
import { FaUserCircle } from 'react-icons/fa'
// redux
import { useSelector } from 'react-redux'
// Utils
import settings from 'utils/config'

export default function Account(props) {
  let userReducer = useSelector((state) => state.userReducer)

  return (
    <div className={styles.container}>
      <div className={styles.containerAcount} onClick={props.GoClick}>
        <FaUserCircle className={styles.icon_user} />
        <h2 className={styles.text} >
          {userReducer.info_user.first_name !== '' && (userReducer[settings.token_access] && userReducer[settings.token_access] !== '')
            ? userReducer.info_user.first_name
            : '¿Ya eres cliente?'}
        </h2>
      </div>
    </div>
  )
}
