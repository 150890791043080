import React, { useState, useEffect } from 'react'
// styles
import styles from './Landing.module.css'
// api
import { getDataSimulator } from '../../Api/getDataSimulator'
// Components
import Header from '../../components/Header'
import Title from './Components/title/Title'
import Resume from './Components/resume/Resume'
import Loader from '../../components/loader/Loader'
import LandingSliders from './Components/landingSliders/LandingSliders'
import ModalLanding from './Components/Modal/ModalLanding/ModalLanding'
// utils
import { inicio } from 'utils/pathName'
import settings from '../../utils/config'
import simulatorCredit from '../../utils/creditSimulator'
// libraires
import { useHistory } from 'react-router-dom'
import { setInfoCredit } from 'redux/Actions/Actions'
import { useDispatch, useSelector } from 'react-redux'
// Image
import superIntendenciaIndustriaComercio from '../../images/logo-sic-footer.png'

export default function Landing(props) {
  let dispatch = useDispatch()
  const [fee, setFee] = useState(1)
  const [amount, setAmount] = useState(400000)
  const [loading, setLoading] = useState(false)
  const [dataSimulatorCredit, setDataSimulatorCredit] = useState({})
  let userReducer = useSelector((state) => state.userReducer)
  let history = useHistory()

  useEffect(() => {
    if (userReducer[settings.token_access]) {
      history.push(inicio)
    } else {
      apiGetDataSimulator()
    }
  }, [])

  useEffect(() => {
    let responseSimulatorCredit = simulatorCredit(
      fee,
      parseFloat(userReducer.info_credit.vat),
      parseFloat(userReducer.info_credit.endorsement),
      parseFloat(userReducer.info_credit.central_risk),
      parseFloat(userReducer.info_credit.interest_rate),
      amount,
      userReducer.info_credit.list_values_credit_study_per_month,
      true
    )
    setDataSimulatorCredit(responseSimulatorCredit)
    dispatch(
      setInfoCredit({
        ...userReducer.info_credit,
        fee,
        amount,
        monthlyCost: responseSimulatorCredit.monthlyCost,
      })
    )
  }, [fee, amount])

  const apiGetDataSimulator = () => {
    setLoading(true)
    let dataToSend = { type_dash: process.env.REACT_APP_TYPE_USER }
    getDataSimulator(dataToSend).then((response) => {
      setLoading(false)
      console.log(`response`, response)
      let { data, errored } = response
      if (errored) {
      } else {
        let responseSimulatorCredit = simulatorCredit(
          fee,
          parseFloat(data.ms_configuration_simulator.vat),
          parseFloat(data.ms_configuration_simulator.endorsement),
          parseFloat(data.ms_configuration_simulator.central_risk),
          parseFloat(data.ms_configuration_simulator.interest_rate),
          amount,
          data.ms_configuration_simulator.list_values_credit_study_per_month,
          true
        )
        setDataSimulatorCredit(responseSimulatorCredit)
        dispatch(
          setInfoCredit({
            ...userReducer.info_credit,
            fee,
            amount,
            monthlyCost: responseSimulatorCredit.monthlyCost,
            vat: parseFloat(data.ms_configuration_simulator.vat),
            endorsement: parseFloat(
              data.ms_configuration_simulator.endorsement
            ),
            central_risk: parseFloat(
              data.ms_configuration_simulator.central_risk
            ),
            interest_rate: parseFloat(
              data.ms_configuration_simulator.interest_rate
            ),
            annual_interest_rate: parseFloat(
              data.ms_configuration_simulator.annual_interest_rate
            ),
            list_values_credit_study_per_month: data.ms_configuration_simulator.list_values_credit_study_per_month
          })
        )
      }
    })
  }

  return (
    <div className={styles.LandingStyle}>
      <Loader loading={loading} />
      <Header />
      <div className={styles.mainCenter}>
        <div className={styles.main}>
          <div className={styles.Container}>
            <Title />
            <br></br>
            <LandingSliders
              fee={fee}
              noEntra={2}
              setFee={setFee}
              amount={amount}
              setAmount={setAmount}
            />
          </div>
          <div className={styles.resume}>
            {JSON.stringify(dataSimulatorCredit) !== '{}' && (
              <Resume dataSimulatorCredit={dataSimulatorCredit} />
            )}
            <ModalLanding />
          </div>
        </div>
      </div>
      <div className={styles.containerSICLogo}>
        <img src={superIntendenciaIndustriaComercio} alt='Superintendencia de industria y comercio' />
      </div>
      <div className={styles.containerTheFirm}>
        <p className={styles.textTheFirm}>THE FIRM COLOMBIA SAS</p>
      </div>
    </div>
  )
}
