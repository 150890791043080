import { useState } from "react";
// components
import ImageAndText from "../../components/ImageAndText/ImageAndText";
import ArrowBack from "components/arrowBack/ArrowBack";
import ModalAlert from "components/modalAlert/ModalAlert";
import InfoCredito from "components/InfoCredito/InfoCredito";
// styles
import styles from "./InformationData.module.css";
// Images
import image from "../../../../images/Credit/Information/modal_documento_en_mano.png";
import imageInformation from "../../../../images/Credit/Information/informacion_basica.png";
// Libraries
import { useHistory } from "react-router-dom";
import { FormBasicTest } from "./Components/formBasic/FormBasicTest";
// redux
import { setInfoAuth } from "redux/Actions/Actions";
import { useDispatch, useSelector } from "react-redux";
// Utils
import { cleanUpDataSignUp } from "utils/clearDataSignUp";

export default function Information_Data() {
  const [isModalAlert, setIsModalAlert] = useState(true);
  let dispatch = useDispatch();
  const history = useHistory();
  let userReducer = useSelector((state) => state.userReducer);
  let userDataSignUp = useSelector((state) => state.userSignUpReducer);

  function goBackArrow() {
    cleanUpDataSignUp(dispatch, userDataSignUp)
    dispatch(
      setInfoAuth({
        ...userReducer.info_auth,
        cellphone: "",
      })
    );
    history.goBack();
  }

  return (
    <div style={{ height: "100vh" }}>
      <ModalAlert
        image={image}
        isTwoButton={false}
        buttonCloseText="Volver"
        isModalAlert={isModalAlert}
        colorTitle={"var(--MEDIUM_BLUE)"}
        buttonSuccessText="Ya la tengo!"
        text2="Tu documento de identidad"
        setIsModalAlert={setIsModalAlert}
        text="Antes de empezar debes tener a la mano"
        backgroundColorSuscces={"var(--COLOR_SUCCESS)"}
        buttonSuccessClick={() => setIsModalAlert(false)}
      />

      <div className={styles.home}>
        <ArrowBack
          showLogo
          backText="Volver al home"
          onClick={() => goBackArrow()}
        />
        <ImageAndText
          simpleStyle={true}
          image={imageInformation}
          text="Para empezar el estudio de tu crédito solo necesitaremos unos cuantos datos"
        />
        <br />
        <div className={styles.infoCredito}>
          <InfoCredito />
        </div>
        <FormBasicTest />
      </div>
    </div>
  );
}
