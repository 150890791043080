import React, { useEffect, useState } from "react";
// Styles
import styles from "./Empleado.module.css";
// Components
import TheInputFormik from "components/TheInput/TheInputFormik";
// Utils
import { capitalizeFirstLeter } from "utils/capitalizeLetter";
import TheSelect from "components/TheSelect/TheSelect";
// Redux
import { useSelector } from "react-redux";
// API
import { getListCompanies } from "Api/companies/companies";
import Loader from "components/loader/Loader";

export default function Empleado(props) {
  let { values, companySelected, setCompanySelected } = props
  let userReducer = useSelector((state) => state.userReducer)
  const [loading, setLoading] = useState(false);

  const [listCompanies, setListCompanies] = useState([])

  useEffect(() => {
    apiGetListCompanies()
  }, [])

  function apiGetListCompanies() {
    setLoading(true)
    getListCompanies().then((response) => {
      setLoading(false)
      let { data, errored } = response
      if (errored) {
      } else {
        setListCompanies(data.companies)
      }
    })
  }

  return (
    <div className={styles.Container}>
      <Loader loading={loading} />
      <div className={styles.divInfoContainer}>
        <p className={styles.TextInput}>Nombre de la empresa donde laboras</p>
        <div className={styles.container_input}>
          <TheSelect
            options={listCompanies}
            checkedOption={companySelected.label}
            setCheckedOption={setCompanySelected}
            nameText="Selecciona la empresa donde laboras"
          />
        </div>
      </div>
    </div>
  );
}
