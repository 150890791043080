// styles
import styles from "./toast_custom.module.css"
// libraries
import { toast } from "react-toastify";
import { FaTimesCircle, FaCheckCircle, FaInfoCircle } from "react-icons/fa";

export function toast_custom(description, state, time_autoclose, position) {
  let renderData = () => {
    return (
      <div className={styles.container_toast}>
        {state === "error" ? (
          <FaTimesCircle className={styles.icon_toast} />
        ) : state === "success" ? (
          <FaCheckCircle className={styles.icon_toast} />
        ) : (
          <FaInfoCircle className={styles.icon_toast} />
        )}
        <p>{description}</p>
      </div>
    );
  };

  let params = {
    icon: false,
    draggable: true,
    theme: 'colored',
    closeOnClick: true,
    position: position,
    progress: undefined,
    hideProgressBar: true,
    autoClose: time_autoclose,
  };

  state === "error"
    ? toast.error(renderData(), params)
    : state === "success"
    ? toast.success(renderData(), params)
    : toast.warning(renderData(), params);
}
