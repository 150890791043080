import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "GET";
const SUCCESS_STATUS_CODE = 200;

function postGetDataUser(dataToSend, token) {
  const endPoint = `/users/api/data/`;
  let API_GET_PROFILE = endPointAPI + endPoint;
  return APICallback(
    dataToSend,
    API_GET_PROFILE,
    METHOD,
    SUCCESS_STATUS_CODE,
    true,
    token,
    false,
    '',
    false,
    '',
    false,
    '',
  );
}

export { postGetDataUser };
