import React from "react";
import { Modal } from "react-bootstrap";
import styles from "./ModalValidate.module.css";
// Images
import imagen from "images/Credit/Information/validando_credito.png";

export default function ModalValidate(props) {
  let { open, setOpen } = props;

  const modalVerificationCredit = (
    <div className={styles.alignModal}>
      <div className={styles.modalContainer}>
        <img
          src={imagen}
          className={styles.modalIMG}
          alt="Estámos validando tu crédito"
        />

        <h2 className={styles.colorLightBlue}>
          Estámos validando tu crédito
        </h2>
        <p style={{ color: "var(--COLOR_TEXT)" }}>
          En un momento estaremos contigo
        </p>
        <p className={styles.textInfo}>
          (No cierres esta ventana. El proceso no tardará)
        </p>
      </div>
    </div>
  );

  return (
    <Modal centered show={open} onHide={() => setOpen(true)}>
      {modalVerificationCredit}
    </Modal>
  );
}
