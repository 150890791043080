import React, { useState } from "react";
//styles
import styles from "./ModalPayCredit.module.css";
//libraries
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
// Components
import TheButton from "../TheButton/TheButton";
import NumberFormat from "react-number-format";
import { toast_custom } from "components/toast_custom/toast_custom";
// Images
import image from "images/LogoConvenios.png";
// Redux
import { useDispatch, useSelector } from "react-redux";
// Utils
import settings from "utils/config";
// Api
import { postPayCredit } from "Api/credit/postPayCredit";
import { apiRefreshToken } from "utils/Hooks/useApiRefreshToken";
import { postPayCreditLinkGenerate } from "Api/dashboard/postPayCreditLinkGenerate";

let dashboard = "/tablero";

export default function ModalPayCredit(props) {
  const [amountPayUser, setAmountPayUser] = useState();
  const [typePayment, setTypePayment] = useState('fullPayment');

  const history = useHistory();
  const dispatch = useDispatch();
  const userReducer = useSelector((state) => state.userReducer);

  const {
    user,
    text,
    idCredit,
    valueFee,
    idPayment,
    setLoading,
    isModalAlert,
    setPaymentLink,
    setIsModalAlert,
  } = props;

  const apiPostPayCredit = () => {
    if (typePayment === 'partialPayment' && amountPayUser === '') {
      toast_custom(
        "Debes ingresar el monto del abono",
        "error",
        4000,
        "top-right"
      );
      return
    }
    if (typePayment === 'partialPayment' && amountPayUser <= 10000) {
      toast_custom(
        "El abono parcial debe ser mayor a $10.000",
        "error",
        4000,
        "top-right"
      );
      return
    }
    setLoading(true);
    const token = userReducer[settings.token_access];
    const dataToSend = { id_payment: idPayment };
    if (typePayment === 'partialPayment') {
      dataToSend.amount_custom_user = +amountPayUser
    }
    postPayCredit(dataToSend, token).then((response) => {
      setLoading(false);
      const { data, errored } = response;
      if (errored) {
        if (data.code === "token_not_valid") {
          apiRefreshToken(dispatch, userReducer, history, apiPostPayCredit);
        }
        if (data.err_code === "OBJECT_NOT_FOUND") {
          toast_custom(
            "No se encontró el id del pago",
            "error",
            4000,
            "top-right"
          );
        }
      } else {
        setAmountPayUser('')
        setIsModalAlert(false)
        if (setPaymentLink) {
          setPaymentLink(`https://${data.url}`)
        } else {
          window.open(`https://${data.url}`, '_blank')
        }
      }
    });
  };

  const apiPostPayCreditDashboard = () => {
    if (typePayment === 'partialPayment' && amountPayUser === '') {
      toast_custom(
        "Debes ingresar el monto del abono",
        "error",
        4000,
        "top-right"
      );
      return
    }
    if (typePayment === 'partialPayment' && amountPayUser <= 10000) {
      toast_custom(
        "El abono parcial debe ser mayor a $10.000",
        "error",
        4000,
        "top-right"
      );
      return
    }
    setLoading(true);
    const token = userReducer[settings.token_access];
    const dataToSend = {
      dni: user.dni,
      idCredit,
    };

    if (typePayment === 'partialPayment') {
      dataToSend.amount = +amountPayUser
      dataToSend.typePayment = typePayment
    }
    postPayCreditLinkGenerate(dataToSend, token).then((response) => {
      setLoading(false);
      const { data, errored } = response;
      if (errored) {
        if (data.code === "token_not_valid") {
          apiRefreshToken(dispatch, userReducer, history, apiPostPayCredit);
        }
        if (data.err_code === "OBJECT_NOT_FOUND") {
          toast_custom(
            "No se encontró el id del pago",
            "error",
            4000,
            "top-right"
          );
        }
      } else {
        setAmountPayUser('')
        setIsModalAlert(false)
        if (setPaymentLink) {
          setPaymentLink(`https://${data.url}`)
        } else {
          window.open(`https://${data.url}`, '_blank')
        }
      }
    });
  };

  return (
    <Modal
      centered
      size="l"
      show={isModalAlert}
      onHide={() => { if (setIsModalAlert) setIsModalAlert(!isModalAlert) }}
    >
      <Modal.Body className={styles.modal_body}>
        <div className={styles.body}>
          <div className={styles.container_image}>
            <img src={image} alt="Success" className={styles.image_logo} />
          </div>
          <p className={styles.text_info}>¿Desea hacer un abono parcial o completo de la siguiente cuota?</p>
          <p className={styles.modal_title} style={{ color: "var(--MEDIUM_BLUE)" }}>{text}</p>
          <div className={styles.infoCredit}>
            <label
              className={styles.labelTitle}
              style={{ color: typePayment === 'fullPayment' && 'var(--COLOR_SUCCESS)' }}
              onClick={() => setTypePayment('fullPayment')}>
              Cuota completa
            </label>
            <label
              className={styles.labelTitle}
              style={{ color: typePayment === 'partialPayment' && 'var(--COLOR_SUCCESS)' }}
              onClick={() => setTypePayment('partialPayment')}>
              Abono parcial
            </label>
          </div>
          {typePayment === 'partialPayment' &&
            <>
              <p className={styles.label}>
                Monto de abono
              </p>
              <NumberFormat
                prefix={'$'}
                thousandSeparator={true}
                // allowLeadingZeros={false}
                placeholder="Ej: $300.000"
                className={styles.inputToPay}
                onValueChange={(e) => setAmountPayUser(e.value)}
                value={
                  history.location.pathname.includes(dashboard)
                    ? +amountPayUser
                    : +amountPayUser > +valueFee ? valueFee : amountPayUser
                }
              />
            </>
          }
          <div
            className={styles.container_button}
            style={{ width: "100%" }}
          >
            <TheButton
              GoClick={() => {
                if (history.location.pathname.includes(dashboard)) {
                  apiPostPayCreditDashboard()
                } else {
                  apiPostPayCredit()
                }
              }}
              background={"var(--MEDIUM_BLUE)"}
              nameText={typePayment === 'partialPayment' ? 'Abonar' : 'Pagar cuota'}
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
