import React from "react";
//libraries
import { Route, useHistory } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
// Utils
import settings from '../config';
import { landing } from '../pathName';

const ActiveSession = ({ component: Component }) => {
  let history = useHistory();
  let userReducer = useSelector((state) => state.userReducer);

  return (
    <Route
      render={(props) =>
        !userReducer[settings.token_access] || userReducer[settings.token_access] === "" ? (
          history.replace(landing)
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default ActiveSession;
