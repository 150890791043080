import { useState } from 'react'
// styles
import styles from "./InfoCode.module.css";
// components
import TheButton from "components/TheButton/TheButton";
import TheInputFormik from "components/TheInput/TheInputFormik";
import { toast_custom } from "components/toast_custom/toast_custom";
import CountDownTimer from 'components/countDownTimer/countDownTimer';
// Icons
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
// libraries
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
// Utils
import settings from 'utils/config';
import { disbursement } from "utils/pathName";
// REDUX
import { useDispatch, useSelector } from "react-redux";
// API
import { apiRefreshToken } from 'utils/Hooks/useApiRefreshToken';
import { postPromissoryNoteOTPValidate } from 'Api/credit/postPromissoryNoteOTPValidate';

export default function InfoCode(props) {
  const history = useHistory()
  let dispatch = useDispatch();
  let { setLoading, apigetPromissoryNoteOTP } = props
  let userReducer = useSelector((state) => state.userReducer);
  let minSecs = { minutes: 1, seconds: 0 };
  const [isDisabled, setIsDisabled] = useState(true);

  const [checks, setChecks] = useState({
    numberCode: '',
    prezaval: false,
    discountAuthorization: false,
    promissoryNoteInstruccionesLetter: false
  })

  function handleChange(e) {
    if (e.target.name === 'numberCode') {
      setChecks({
        ...checks,
        [e.target.name]: e.target.value
      })
    } else if (e.target.name === 'prezaval') {
      setChecks({
        ...checks,
        [e.target.name]: e.target.checked
      })
    } else {
      setChecks({
        ...checks,
        [e.target.name]: e.target.checked
      })
    }
  }

  function apiPostPromissoryNoteOTPValidate(token_access, code) {
    if (!checks.promissoryNoteInstruccionesLetter) {
      toast_custom(
        "Desbes aprobar el pagaré y la carta de instrucciones",
        "error",
        4000,
        "top-right"
      );
      return
    }
    if (!checks.prezaval) {
      toast_custom(
        "Desbes aprobar la aceptación garantías",
        "error",
        4000,
        "top-right"
      );
      return
    }
    if (!checks.discountAuthorization) {
      toast_custom(
        "Desbes aprobar el descuento por libranza",
        "error",
        4000,
        "top-right"
      );
      return
    }
    let token = token_access;
    let dataToSend = {
      code: code || checks.numberCode,
      installments: userReducer.info_credit.fee,
      id_credit: userReducer.info_credit.id_credit,
      acquired_debt: userReducer.info_credit.amount
    };

    toast_custom(
      "Sé paciente, este proceso podría tardar unos segundos",
      "warning",
      4000,
      "top-right"
    );
    setLoading(true);
    postPromissoryNoteOTPValidate(dataToSend, token).then((response) => {
      setLoading(false);
      console.log(`response promission Note OTP validate`, response);
      let { data, errored, status } = response;
      if (errored) {
        if (data.code === "token_not_valid") {
          apiRefreshToken(dispatch, userReducer, history, apiPostPromissoryNoteOTPValidate);
        }
        switch (status !== 201) {
          case "USER_EMAIL_EXISTS":
            toast_custom(
              "El código no coincide",
              "error",
              4000,
              "top-right"
            );
            break;
          default:
            console.log("err api: ", errored);
            toast_custom(
              "Debes volver a solicitar el código",
              "error",
              4000,
              "top-right"
            );
            break;
        }
      } else {
        history.replace(disbursement)
      }
    }).catch((err) => {
      console.log('err', err)
    })
  }

  let cellphone = userReducer.info_user.cellphone.toString()
  let cellphoneToShow = cellphone && cellphone.substr(cellphone.length - 2);

  let urlDocs = settings.local_debug
    ? `${settings.endPointServerURL}/media/`
    : settings.local_debug_stage
      ? "https://preztum-dev.s3.amazonaws.com/media_stage/"
      : 'https://preztum-dev.s3.amazonaws.com/media_production/'

  return (
    <div className={styles.Container}>
      <p className={styles.introText}>
        A continuación, ingresa el código que te enviaremos a tu celular y
        correo electrónico para empezar con el desembolso de
      </p>
      <p className={styles.introText}>
        <span
          style={{ color: "var(--MEDIUM_BLUE)", fontFamily: "var(--FONT_BOLD)" }}
        >
          TU DINERO
        </span>{" "}
        y hacer efectiva la firma del pagaré
      </p>
      <br />
      <p className={styles.introText}>
        El mensaje de texto (SMS) llegará al número:
      </p>
      <p
        className={styles.introText}
        style={{ fontFamily: "var(--FONT_BOLD)" }}
      >
        *** *** **-{cellphoneToShow}
      </p>
      <br />
      <Formik
        initialValues={{
          numberCode: undefined
        }}
        validationSchema={
          Yup.object({ numberCode: Yup.string().required("Requerido") })
        }
        onSubmit={(values) => {
          setChecks({ ...checks, numberCode: values.numberCode })
          apiPostPromissoryNoteOTPValidate(userReducer[settings.token_access], values.numberCode)
        }}
      >
        {(formik) => {
          return (
            <Form>
              <p className={styles.label}>Código de ingreso</p>
              <div className={styles.ContainerInput}>
                <TheInputFormik
                  type="number"
                  format="# # # #"
                  name="numberCode"
                  nameText="ingresa el código"
                  onChange={formik.setFieldValue}
                  value={formik.values.numberCode}
                />
              </div>
              <p className={styles.smallLeter}>
                El código llega al nro. de teléfono con el cual te registraste
              </p>
              <p className={styles.countdown}>
                <CountDownTimer
                  minSecs={minSecs}
                  setIsDisabled={setIsDisabled}
                />
              </p>
              <p className={styles.not_code}>¿No te llega el código?</p>
              <p
                className={styles.request_again}
                style={{ color: isDisabled && "var(--COLOR_DISABLED)" }}
                onClick={() => {
                  if (!isDisabled) {
                    setIsDisabled(true)
                    apigetPromissoryNoteOTP()
                  }
                }}
              >
                Solicitar nuevamente
              </p>
              <br />
              <label className={styles.container_check}>
                {!checks.promissoryNoteInstruccionesLetter ? (
                  <ImCheckboxUnchecked className={styles.icon} />
                ) : (
                  <ImCheckboxChecked className={styles.iconChecked} />
                )}
                <input
                  hidden
                  type="checkbox"
                  name='promissoryNoteInstruccionesLetter'
                  onChange={handleChange} style={{ marginRight: ".5rem" }}
                />
                Apruebo el{" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                  href={`${urlDocs}files/docs/Pagare_carta_instrucciones.pdf`}
                >
                  pagaré
                </a>{" "} y la {" "}
                <a
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                  href={`${urlDocs}files/docs/Pagare_carta_instrucciones.pdf`}
                >
                  carta de instrucciones
                </a>{" "}
                asociado al crédito
              </label>
              <br />
              <label className={styles.container_check}>
                {!checks.prezaval ? (
                  <ImCheckboxUnchecked className={styles.icon} />
                ) : (
                  <ImCheckboxChecked className={styles.iconChecked} />
                )}
                <input
                  hidden
                  type="checkbox"
                  name='prezaval'
                  onChange={handleChange} style={{ marginRight: ".5rem" }}
                />
                <a
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                  href={`${urlDocs}files/docs/aceptacion_garantia.pdf`}
                >
                  Aceptación de garantía
                </a>
              </label>
              <br />
              <label className={styles.container_check}>
                {!checks.discountAuthorization ? (
                  <ImCheckboxUnchecked className={styles.icon} />
                ) : (
                  <ImCheckboxChecked className={styles.iconChecked} />
                )}
                <input
                  hidden
                  type="checkbox"
                  name='discountAuthorization'
                  onChange={handleChange} style={{ marginRight: ".5rem" }}
                />
                <a
                  target="_blank"
                  rel="noreferrer"
                  className={styles.link}
                  href={`${urlDocs}files/docs/autorizacion_descuento_libranza.pdf`}
                >
                  Autorización de descuento por libranza
                </a>
              </label>
              <br />
              <div className={styles.ContainerInput}>
                <TheButton
                  nameText="Aprobar desembolso"
                  background="var(--MEDIUM_BLUE)"
                />
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
  );
}
