import styles from "./Bubble.module.css";

export default function Bubble(props) {

  return (
    <>
      <a
        target="_blank"
        rel="noreferrer"
        onClick={props.GoClick}
        className={styles.container}
        href="https://api.whatsapp.com/send?phone=573113392881&text=Hola, Necesito más información!"
      >
        <props.icon className={styles.icon} />
      </a>
    </>
  );
}
