import { useState } from "react";
//styles
import styles from "./ModalEditInfoWork.module.css";
//libraries
import * as Yup from "yup";
import { Modal } from "react-bootstrap";
import { Formik, Form } from "formik";
// Redux
import { useDispatch } from "react-redux";
// Components
import { FaTimes } from "react-icons/fa";
import TabPanel from "../TabPanel/TabPanel";
import TheButton from "../TheButton/TheButton";
import { toast_custom } from "components/toast_custom/toast_custom";
import InfoWorkMoneyPerson from "../infoWorkMoneyPerson/InfoWorkMoneyPerson";

export default function ModalEditInfoWork(props) {

  let { dataUserAndWork, setDataUserAndWork } = props
  let dispatch = useDispatch();
  // state
  const [idJobSelected, setIdJobSelected] = useState(dataUserAndWork ? dataUserAndWork.activity : null);
  const [tabNameEmploye, setTabNameEmploye] = useState(
    dataUserAndWork.type_occupation
  );

  const validate = Yup.object({
    monthly_income: Yup.number().required("Requerido"),
    number_people_in_charge: Yup.number().required("Requerido"),
    company:
      tabNameEmploye === 'employee' &&
      Yup.string()
        .required("Requerido"),
  });

  return (
    <Modal
      centered
      size="l"
      show={props.isModalEditWork}
      onHide={() => props.setModalEditWork(!props.isModalEditWork)}
    >
      <Modal.Body className={styles.modal_body}>
        <div className={styles.body}>
          <div className={styles.close}>
            <FaTimes
              onClick={() => props.setModalEditWork(!props.isModalEditWork)}
            />
          </div>
          <p className={styles.modal_title}>Editar información laboral</p>
          <Formik
            initialValues={{
              company: dataUserAndWork.company,
              monthly_income: dataUserAndWork.monthly_income,
              number_people_in_charge: dataUserAndWork.number_people_in_charge,
            }}
            validationSchema={validate}
            onSubmit={(values) => {
              if (!tabNameEmploye && idJobSelected === null) {
                toast_custom(
                  "Upps, debes seleccionar la actividad que desempeñas",
                  "error",
                  4000,
                  "top-right"
                );
              } else {
                setDataUserAndWork({
                  ...dataUserAndWork,
                  company: values.company,
                  activity: idJobSelected,
                  type_occupation: tabNameEmploye,
                  monthly_income: values.monthly_income,
                  number_people_in_charge: values.number_people_in_charge
                })
                props.setModalEditWork(false)
              }
            }}
          >
            {(formik) => {
              const { dirty, values, isValid, submitForm } = formik;
              return (
                <div className={styles.content_modal}>
                  <div className={styles.formEdit}>
                    <Form className={styles.form}>
                      <TabPanel
                        values={values}
                        formik={formik}
                        idJobSelected={idJobSelected}
                        tabNameEmploye={tabNameEmploye}
                        setIdJobSelected={setIdJobSelected}
                        setTabNameEmploye={setTabNameEmploye}
                      />
                      <InfoWorkMoneyPerson values={values} formik={formik} />
                      <div className={styles.containerButton}>
                        <TheButton
                          nameText="Guardar"
                          GoClick={() => {
                            if (dirty && isValid) submitForm();
                          }}
                          background={"var(--MEDIUM_BLUE)"}
                        />
                      </div>
                    </Form>
                  </div>
                </div>
              );
            }}
          </Formik>
        </div>
      </Modal.Body>
    </Modal>
  );
}
