// Styles
import styles from "./LoanInformation.module.css";
// utils
import cashFormatter from "utils/cashFormatter";
import { dateFormater } from '../../../../utils/dateFormater';
// Components
import ItemToCreditActive from "./components/itemToCreditActive/ItemToCreditActive";

export default function LoanInformation(props) {
  let { credit } = props

  return (
    <div className={styles.container_info}>
      <ItemToCreditActive
        textLabel='Fecha Inicial'
        textValue={dateFormater(credit.request_date)}
      />

      <ItemToCreditActive
        textLabel='Fecha Final'
        textValue={dateFormater(credit.finish_date)}
        stylesSpan={{ color: credit.next_payment.has_past_due ? 'red' : 'var(--COLOR_TEXT)' }}
      />

      <ItemToCreditActive
        stylesSpan={{ color: "var(--MEDIUM_BLUE)" }}
        textValue={cashFormatter(credit.monthly_fee / 2)}
        textLabel={`Cuota Quincenal`}
      />
    </div>
  );
}
