import React from "react";
import styles from "./TabPanel.module.css";
// Components
import Empleado from "../../views/Credit/Information/information_work/components/Empleado/Empleado";
import Independiente from "../../views/Credit/Information/information_work/components/Independiente/Independiente";

export default function TabPanel(props) {
  let {
    values,
    formik,
    idJobSelected,
    tabNameEmploye,
    setIdJobSelected,
    setTabNameEmploye,
    companySelected,
    setCompanySelected
  } = props;

  return (
    <>
      <div className={styles.tabContainer}>
        <div
          style={{ borderRadius: "5px 0 0 5px" }}
          onClick={() => setTabNameEmploye('employee')}
          className={tabNameEmploye === 'employee' ? styles.tabActive : styles.tabInactive}
        >
          Empleado
        </div>
      </div>
      {tabNameEmploye === 'employee' ? (
        <Empleado values={values} formik={formik}
          companySelected={companySelected}
          setCompanySelected={setCompanySelected}
        />
      ) : (
        tabNameEmploye === 'independent' ? (
          <Independiente
            values={values}
            formik={formik}
            idJobSelected={idJobSelected}
            setIdJobSelected={setIdJobSelected}
          />) : <></>
      )}
    </>
  );
}
