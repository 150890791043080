import styles from "./Title.module.css";

export default function Title() {
  return (
    <>
      <h2 className={styles.title}>
        Todos necesitamos que nos den una mano de vez en cuando
      </h2>

      <p className={styles.text}>
        Tranquilo, nosotros te entendemos y te apoyamos en tu día a día
        <br /> para que puedas seguir adelante
      </p>
    </>
  );
}
