//styles
import styles from "./modalLoginEmail.module.css";
//libraries
import * as Yup from "yup";
import Modal from "@material-ui/core/Modal";
import { useHistory } from "react-router-dom";
import { FaArrowLeft, FaTimes } from "react-icons/fa";
import { Formik, Form, ErrorMessage } from "formik";
// redux
import { useDispatch, useSelector } from "react-redux";
import { setInfoUser, setInfoAuth } from "redux/Actions/Actions";
//Components
import TheInput from "components/TheInput/TheInput";
import TheButton from "components/TheButton/TheButton";
import ImageInfoLogin from "../components/imageInfoLogin/ImageInfoLogin";
import Asterisco from "components/Asterisco/Asterisco";
// Api
import { postEmailLogin } from "Api/login/postEmailLogin";
// Utils
import { inicio } from "utils/pathName";
import { toast_custom } from "components/toast_custom/toast_custom";

const ModalLoginEmail = (props) => {
  const history = useHistory();
  let dispatch = useDispatch();
  let userReducer = useSelector((state) => state.userReducer);

  const apiPostEmailLogin = (email, password) => {
    let dataToSend = {
      password: password,
      email: email.toLowerCase(),
    };
    postEmailLogin(dataToSend, password)
      .then((response) => {
        console.log(`response email login`, response);
        let { data, errored } = response;
        if (errored) {
          switch (data.err_code) {
            case "USER_NOT_FOUND":
              toast_custom(
                "El usuario o la contraseña son incorrectos",
                "error",
                4000,
                "top-right"
              );
              break;
            default:
              toast_custom(
                "Upss, Estamos presentando problemas",
                "error",
                4000,
                "top-right"
              );
              break;
          }
        } else {
          dispatch(
            setInfoAuth({
              token_access: data.data_login.tokens.access,
              token_refresh: data.data_login.tokens.refresh,
              ...userReducer.info_auth,
              cellphone: +data.data_login.user_data.cellphone,
            })
          );
          dispatch(
            setInfoUser({
              ...userReducer.info_user,
              dni: data.data_login.user_data.dni,
              photo: data.data_login.user_data.photo,
              email: data.data_login.user_data.email,
              id_user: data.data_login.user_data.id_user,
              last_name: data.data_login.user_data.last_name,
              cellphone: +data.data_login.user_data.cellphone,
              genre_key: data.data_login.user_data.genre_key,
              first_name: data.data_login.user_data.first_name,
              genre_label: data.data_login.user_data.genre_label,
              type_document: data.data_login.user_data.type_document,
              type_document_label:
                data.data_login.user_data.type_document_label,
            })
          );
          history.push(inicio);
        }
      })
      .catch((err) => {
        toast_custom(
          "Upps, Estamos presentando problemas",
          "error",
          4000,
          "top-right"
        );
      });
  };

  return (
    <Modal
      open={props.isModalLogin}
      onClose={() => props.setModalLogin(!props.isModalLogin)}
    >
      <div className={styles.principal_container}>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Ingresa un correo válido")
              .required("El correo es requerido")
              .matches(
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-.]+)*$/,
                "Solo debe contener las letras (a-z), los numeros de (0-9) y puntos (.)"
              ),
            password: Yup.string()
              .min(8, "La contraseña debe tener mínimo 8 caracteres")
              .matches(/[0-9]/, "La contraseña debe tener al menos un número")
              .matches(
                /[A-Z]/,
                "La contraseña debe tener al menos una mayúscula"
              )
              .required("La contraseña es requerida"),
          })}
          onSubmit={(values) =>
            apiPostEmailLogin(values.email, values.password)
          }
        >
          {(formik) => {
            const { values } = formik;
            return (
              <div className={styles.content_modal}>
                <FaArrowLeft
                  className={styles.arrow_icon}
                  onClick={() => {
                    props.setModalLogin(!props.isModalLogin);
                    props.setModalLoginCellphone(true);
                  }}
                />
                <div className={styles.section_info}>
                  <ImageInfoLogin image="images/components/coffe_cup_login.png" />
                </div>
                <div className={styles.form_login}>
                  <FaTimes
                    className={styles.close_icon}
                    onClick={() => props.setModalLogin(!props.isModalLogin)}
                  />
                  <p className={styles.login_title}>Iniciar sesión</p>
                  <p className={styles.subtitle}>
                    Ingresa tu correo y contraseña para ingresar a tu portal
                  </p>
                  <Form className={styles.form}>
                    <div className={styles.input}>
                      <p className={styles.label} style={{ marginTop: "0px" }}>
                        Correo electrónico
                        <Asterisco />
                      </p>
                      <TheInput
                        name="email"
                        value={values.email}
                        onChange={formik.handleChange}
                        nameText="Ej. mario@crediconvenio.com"
                      />
                      <ErrorMessage
                        name="email"
                        component="span"
                        className={styles.label_error}
                      />
                      <p className={styles.label}>
                        Contraseña
                        <Asterisco />
                      </p>
                      <TheInput
                        type="password"
                        name="password"
                        nameText="********"
                        value={values.password}
                        onChange={formik.handleChange}
                      />
                      <ErrorMessage
                        name="password"
                        component="span"
                        className={styles.label_error}
                      />
                      <div style={{ marginTop: "1rem", width: "100%" }}>
                        <TheButton
                          nameText="Ingresar"
                          background={"var(--MEDIUM_BLUE)"}
                        />
                      </div>
                    </div>
                  </Form>
                  <p className={styles.forgot_password}>
                    ¿Olvidaste tu contraseña?
                  </p>
                  <p
                    onClick={() => {
                      props.setModalLogin(false);
                      props.setModalForgotPassword(true);
                    }}
                    className={styles.recover_password}
                  >
                    Recuperar contraseña
                  </p>
                  <p
                    onClick={() => {
                      props.setModalLogin(false);
                      props.setModalLoginCellphone(true);
                    }}
                    className={styles.on_back}
                  >
                    Volver
                  </p>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default ModalLoginEmail;
