import React from "react";
//styles
import styles from "./Loader.module.css";
//libraries
import { Modal } from "react-bootstrap";
// Components
// import CircularProgress from "@material-ui/core/CircularProgress";
import {
  // BlockLoading,
  // LadderLoading,
  // CommonLoading,
  // WindMillLoading,
  // MeteorRainLoading,
  CircleToBlockLoading,
} from "react-loadingg";

export default function Loader(props) {
  return (
    <Modal
      centered
      size="l"
      show={props.loading}
      onHide={props.loading}
    >
      <Modal.Body className={styles.modal_body} style={{padding: 0, background: 'transparent !important'}}>
        <div className={styles.body}>
          <div className={styles.principal_container}>
            <CircleToBlockLoading color="var(--COLOR_SUCCESS)" />
            <p className={styles.title}>Cargando...</p>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
