import settings from '../utils/config';
import APICallback from '../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "GET";
const SUCCESS_STATUS_CODE = 200;

function getDataSimulator(dataToSend) {
  const endPoint = `/users/api/data/simulator`;
  let API_GET_DATA_SIMULATOR = endPointAPI + endPoint;
  return APICallback(
    dataToSend,
    API_GET_DATA_SIMULATOR,
    METHOD,
    SUCCESS_STATUS_CODE,
    false,
    '',
    false,
    '',
    false,
    '',
    false,
    '',
  );
}

export { getDataSimulator };
