import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "POST";
const SUCCESS_STATUS_CODE = 200;

function postPromissoryNoteOTPValidate(dataToSend, token) {
  // cambiar endpoint
  const endPoint = `/credits/api/promissory/note/management/`;
  let API_POST_PROMISSORY_NOTE_OTP_VALIDATE = endPointAPI + endPoint;
  return APICallback(
    dataToSend,
    API_POST_PROMISSORY_NOTE_OTP_VALIDATE,
    METHOD,
    SUCCESS_STATUS_CODE,
    true,
    token,
    false,
    '',
    false,
    '',
  );
}

export { postPromissoryNoteOTPValidate };
