import { useEffect, useState } from "react";

const CountDownTimer = ({ minSecs, setIsDisabled }) => {
  const { minutes = 1, seconds = 60 } = minSecs;
  const [timerId, setTimerId] = useState("");
  const [[mins, secs], setTime] = useState([minutes, seconds]);

  useEffect(() => {
    let timerId = setInterval(() => tick(), 1000);
    setTimerId(timerId);
    return () => clearInterval(timerId);
  }, [secs]);

  const tick = () => {
    if (mins === 0 && secs === 0) {
      reset();
      setIsDisabled(false);
    } else if (secs === 0) setTime([mins - 1, 59]);
    else setTime([mins, secs - 1]);
  };

  const reset = () => {
    clearInterval(timerId);
    // setTime([parseInt(minutes), parseInt(seconds)])
  };

  return `${mins.toString().padStart(2, "0")}:${secs
    .toString()
    .padStart(2, "0")}`;
};

export default CountDownTimer;
