import React from "react";
//styles
import styles from "./ModalRetrenchment.module.css";
//libraries
import { Modal } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
//Apis
// Utils
import { creditRetrenchment } from "utils/pathName";
// images
import trophe from "../../images/home/retanqueo_aprobado.png";
// Components
import TheButton from "../TheButton/TheButton";

export default function ModalRetrenchment(props) {
  const history = useHistory();
  return (
    <Modal
      centered
      size="l"
      show={props.isModalRetrenchment}
      onHide={() => props.setIsModalRetrenchment(!props.isModalRetrenchment)}
    >
      <Modal.Body className={styles.modal_body}>
        <div className={styles.body}>
          <div className={styles.container_image}>
            <img src={trophe} alt="Success" />
          </div>
          <p className={styles.text_blue}>
            Queremos premiar tu puntualidad y tu compromiso con el pago de tus
            cúotas
          </p>
          <p className={styles.text_info}>
            Tienes un{" "}
            <strong className={styles.important}>retanqueo preaprobado</strong>{" "}
            de tu crédito por un valor de
          </p>
          <p className={styles.text_blue} style={{ fontSize: '1.6rem' }}>$ 720,000 COP</p>
          <div className={styles.container_button}>
            <TheButton
              GoClick={() => history.push(creditRetrenchment)}
              background="var(--MEDIUM_BLUE)"
              nameText="Retanquear cupo"
            />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
