//Utils
import { set_list_banks } from "./ActionsName";
// Components
import { toast_custom } from "components/toast_custom/toast_custom";
// Api
import { getBanks } from "Api/banks/banks";

export function setBanks(token) {
  return async (dispatch) => {
    await getBanks(token).then(response => {
      console.log("getBanks: ", response)
      let { data, errored } = response;
      if(errored) {
        toast_custom(
          "Upss, Estamos presentando problemas",
          "error",
          4000,
          "top-right"
        );
      } else {
        let { banks } = data;
        let list_banks = [];
        banks.forEach(bank => {
          list_banks.push({
            label: bank.title,
            value: bank.id_bank
          })
        })
        dispatch({ type: set_list_banks, banks: list_banks });
      }
    })
  };
}
