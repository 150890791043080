import { setDataUserSignUp } from "redux/Actions/ActionsSignUp";
import { setInfoUser } from "redux/Actions/Actions";

export function cleanUpDataSignUp(dispatch, userDataSignUp, userReducer) {
  if (userReducer) {
    console.log("setInfoUser is OK")
    dispatch(
      setInfoUser({
        ...userReducer.info_user,
        dni: userDataSignUp.dni,
        cellphone: userDataSignUp.cellphone,
        last_name: userDataSignUp.last_name,
        first_name: userDataSignUp.first_name,
      })
    );
  }

  dispatch(
    setDataUserSignUp({
      ...userDataSignUp,
      dni: "",
      city: {},
      email: "",
      genre: {},
      address: "",
      district: "",
      birthday: "",
      last_name: "",
      cellphone: "",
      first_name: "",
      departament: {},
      typeDocument: {},
      confirmEmail: "",
      countryExpedition: {},
      date_dni_expedition: "",

      city_issuance_dni: {},
      departament_issuance_dni: {},

      job: "",
      company: "",
      monthly_income: "",
      type_occupation: 'employee',
      number_people_in_charge: "",
      password: '',
      confirmPassword: ''
    })
  )


}
