import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "POST";
const SUCCESS_STATUS_CODE = 200;

function postConfirmCreditfromEmployee( dataToSend ) {
  const endPoint = `/dashboard/api/allied_company/credit/confirmed_employee`;
  const API_POST_CONFIRM_CREDIT_FROM_EMPLOYEE = endPointAPI + endPoint;

  return APICallback(
    dataToSend,
    API_POST_CONFIRM_CREDIT_FROM_EMPLOYEE,
    METHOD,
    SUCCESS_STATUS_CODE,
    false,
    '',
    false,
    '',
    false,
    '',
  );
}

export { postConfirmCreditfromEmployee };
