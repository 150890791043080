import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "GET";
const SUCCESS_STATUS_CODE = 200;

function getBanks(token) {  
  const endPoint = `/credits/api/bank/list/`;
  let GET_BANKS = endPointAPI + endPoint;
  return APICallback(
    {},
    GET_BANKS,
    METHOD,
    SUCCESS_STATUS_CODE,
    true,
    token,
    false,
    '',
    false,
    '',
    false,
    '',
  );
}

export { getBanks };
