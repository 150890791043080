import { combineReducers } from 'redux';

import userReducer from '../Reducers/userReducer';
import banksReducer from '../Reducers/banksReducer';
import geographyReducer from '../Reducers/geographyReducer';
import userSignUpReducer from '../Reducers/dataUserSignUpReducer';

export default combineReducers({
    userReducer,
    banksReducer,
    geographyReducer,
    userSignUpReducer,
})