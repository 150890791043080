// Styles
import styles from "./Sidebar.module.css";
// components
import { FaHistory, FaPowerOff, FaUserCircle } from "react-icons/fa";
// Images
import Logo from "../../images/LogoConvenios.png";
import { Link } from "react-router-dom";
// utils
import { creditHistory } from "utils/pathName";
// redux
import { useSelector } from "react-redux";

export default function Sidebar(props) {
  let userReducer = useSelector((state) => state.userReducer);

  return (
    <div className={styles.sidebar}>
      <div className={styles.title}>
        <div className={styles.containerLogo}>
          <img src={Logo} alt="CrediConvenio" className={styles.logo} />
        </div>
        <span
          className={styles.times}
          onClick={() => props.setIsSidebar(false)}
        >
          &times;
        </span>
      </div>
      <div className={styles.info_user}>
        <div>
          <div className={styles.container_user}>
            <div>
              <FaUserCircle
                className={styles.icon_user}
                style={{ fontSize: "3rem" }}
              />
            </div>
            <div className={styles.user}>
              <p className={styles.name_user}>
                {userReducer.info_user.first_name !== "" &&
                  userReducer.info_user.first_name}
              </p>
              <p className={styles.welcome}>Bienvenido a tu sesión</p>
            </div>
          </div>

          <Link to={creditHistory} className={styles.links_user}>
            <div>
              <FaHistory className={styles.icon_user} />
            </div>
            Hitorial de créditos
          </Link>
        </div>

        <div
          className={styles.sesion}
          onClick={() => {
            props.setIsModalAlert(true);
            props.setIsSidebar(false);
          }}
        >
          <FaPowerOff className={styles.icon_sesion} />
          Cerrar sesión
        </div>
      </div>
    </div>
  );
}
