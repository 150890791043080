import styles from "./ImageAndText.module.css";

export default function ImageAndText(props) {
  let { colorText } = props;
  return (
    <div className={styles.container}>
      <img
        src={props.image}
        className={styles.img}
        alt="Información básica"
        style={{ marginTop: 20, marginBottom: 20 }}
      />
      <p
        style={{ color: colorText && colorText }}
        className={props.simpleStyle ? styles.simpleStyle : styles.TextColor}
      >
        {props.text}
      </p>
    </div>
  );
}
