import { set_data_user_signUp } from "../Actions/ActionsName";

let INITIAL_STATE = {
  dni: "",
  city: {},
  email: "",
  genre: {},
  address: "",
  district: "",
  birthday: "",
  last_name: "",
  cellphone: "",
  first_name: "",
  departament: {},
  typeDocument: {},
  confirmEmail: "",
  countryExpedition: {},
  date_dni_expedition: "",

  city_issuance_dni: {},
  departament_issuance_dni: {},

  job: "",
  company: "",
  id_company: 0,
  monthly_income: "",
  type_occupation: 'employee',
  number_people_in_charge: "",
  password: '',
  confirmPassword: ''
};

export default function userSignUpReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case set_data_user_signUp: {
      return {
        ...state,
        dni: action.payload.dni,
        city: action.payload.city,
        email: action.payload.email,
        genre: action.payload.genre,
        address: action.payload.address,
        district: action.payload.district,
        birthday: action.payload.birthday,
        cellphone: action.payload.cellphone,
        last_name: action.payload.last_name,
        first_name: action.payload.first_name,
        departament: action.payload.departament,
        typeDocument: action.payload.typeDocument,
        confirmEmail: action.payload.confirmEmail,
        countryExpedition: action.payload.countryExpedition,
        date_dni_expedition: action.payload.date_dni_expedition,

        city_issuance_dni: action.payload.city_issuance_dni,
        departament_issuance_dni: action.payload.departament_issuance_dni,

        job: action.payload.job,
        company: action.payload.company,
        id_company: action.payload.id_company,
        monthly_income: action.payload.monthly_income,
        type_occupation: action.payload.type_occupation,
        number_people_in_charge: action.payload.number_people_in_charge,

        password: action.payload.password,
        confirmPassword: action.payload.confirmPassword,
      };
    }

    default:
      return state;
  }
}
