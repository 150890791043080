import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "POST";
const SUCCESS_STATUS_CODE = 200;

function postCodeLogin(dataToSend) {
  const endPoint = `/users/api/login/complete_authentication_otp/`;
  let API_POST_CODE_LOGIN = endPointAPI + endPoint;
  return APICallback(
    dataToSend,
    API_POST_CODE_LOGIN,
    METHOD,
    SUCCESS_STATUS_CODE,
    false,
    '',
    false,
    '',
    false,
    '',
    false,
    '',
  );
}

export { postCodeLogin };
