// Styles
import styles from "./InfoWorkMoneyPerson.module.css";
// Components
import TheInputFormik from "components/TheInput/TheInputFormik";

export default function InfoWorkMoneyPerson(props) {
  let { values, formik } = props;
  return (
    <div className={styles.SmallDivsContainer}>
      <div className={styles.SmallDivs}>
        <p className={styles.TextInput}>Ingresos mensuales</p>
        <TheInputFormik
          prefix={"$"}
          type="number"
          name="monthly_income"
          nameText="Ej: 1.200.000"
          thousandSeparator={true}
          value={values.monthly_income}
          onChange={formik.setFieldValue}
        />
      </div>
      <div className={styles.SmallDivs}>
        <p className={styles.TextInput}>Nro de personas a cargo</p>
        <TheInputFormik
          format="#"
          type="number"
          nameText="Ej: 2"
          name="number_people_in_charge"
          onChange={formik.setFieldValue}
          value={values.number_people_in_charge}
        />
      </div>
    </div>
  );
}
