import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "GET";
const SUCCESS_STATUS_CODE = 200;

function getState() {  
  const endPoint = `/geography/api/states/`;
  let GET_STATE = endPointAPI + endPoint;
  return APICallback(
    {},
    GET_STATE,
    METHOD,
    SUCCESS_STATUS_CODE,
    false,
    '',
    false,
    '',
    false,
    '',
    false,
    '',
  );
}

export { getState };
