import styles from "./TheText.module.css";

export default function TheText({
  Data,
  nameText,
  widthText,
  ellipsisText,
}) {
  return (
    <div className={ellipsisText ? styles.ContainerLong : styles.Container}>
      <p className={styles.NameText} style={{ width: `${widthText}` }}>
        {nameText}
      </p>
      <p className={!ellipsisText ? styles.Data : styles.longData} style={{ width: `${widthText}` }}>
        {Data}
      </p>
    </div>
  );
}
