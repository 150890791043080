import React from "react";
// styles
import styles from "./ModalValidate.module.css";
// libraries
import { useHistory } from "react-router";
import Modal from "@material-ui/core/Modal";
// images
import imagen from "../../../../../../images/Credit/disbursement/credito_listo_para_desembolso.png";
// components
import TheButton from "components/TheButton/TheButton";
// Utils
import { inicio } from "utils/pathName";

export default function ModalValidate(props) {
  const history = useHistory();

  let message = (
    <p>
      Con la aprobación de tu empresa te estaremos enviando el dinero
      <br />
      donde lo solicitaste
    </p>
  )
  const VerificationModal = (
    <div className={styles.alignModal}>
      <div className={styles.modalContainer}>
        <img className={styles.modalIMG} src={imagen} alt="Validando..." />

        <h2 className={styles.colorGreen}>¡Excelente!</h2>
        <div style={{ textAlign: "center", marginTop: "1rem" }}>
          {message}
        </div>
        <div className={styles.container_button}>
          <TheButton
            nameText="Volver al Inicio"
            background="var(--MEDIUM_BLUE)"
            GoClick={() => history.replace(inicio)}
          />
        </div>
      </div>
    </div>
  );

  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {VerificationModal}
    </Modal>
  );
}
