import { useSelector } from 'react-redux'

import styles from "./FormLinksTyC.module.css";
// Icons
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
// Utils
import settings from "utils/config";

export default function FormLinksTyC(props) {
  const { checks, handleChange } = props
  let userReducer = useSelector((state) => state.userReducer)
  let urlDocs = settings.local_debug
    ? `${settings.endPointServerURL}/media/`
    : settings.local_debug_stage
      ? "https://preztum-dev.s3.amazonaws.com/media_stage/"
      : 'https://preztum-dev.s3.amazonaws.com/media_production/'

  return (
    <div className={styles.Container}>
      <label className={styles.checkboxDisplay}>
        <input
          hidden
          type="checkbox"
          name="dataTreatment"
          className={styles.input}
          onChange={(e) => handleChange(e)}
        />
        {!checks.dataTreatment ? (
          <ImCheckboxUnchecked className={styles.icon} />
        ) : (
          <ImCheckboxChecked className={styles.iconChecked} />
        )}
        <div>
          He leído y acepto las<a className={styles.textColorTerms} href={`${urlDocs}files/docs/PoliticaTratamientoDatosPersonales.pdf`} target="_blank" nonreferences="true" rel="noreferrer"> políticas de tratamiento de datos</a>
        </div>
      </label>
      <label className={styles.checkboxDisplay}>
        <input
          hidden
          name="isTyC"
          type="checkbox"
          className={styles.input}
          onChange={(e) => handleChange(e)}
        />
        {!checks.isTyC ? (
          <ImCheckboxUnchecked className={styles.icon} />
        ) : (
          <ImCheckboxChecked className={styles.iconChecked} />
        )}
        <div>
          He leído y acepto los <a className={styles.textColorTerms} href={`${urlDocs}files/docs/Terminos_y_condiciones_convenios.pdf`} target="_blank" nonreferences="true" rel="noreferrer" >términos y condiciones de la plataforma</a>
        </div>
      </label>
      <label className={styles.checkboxDisplay}>
        <input
          hidden
          type="checkbox"
          name="centralRisk"
          className={styles.input}
          onChange={(e) => handleChange(e)}
        />
      </label>
    </div>
  );
}
