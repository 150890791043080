let local_debug = process.env.REACT_APP_DEBUG_SERVER === 'true' ? true : false,
  prodEndpoint = process.env.REACT_APP_ENDPOINT_PRODUCTION,
  localEndpoint = process.env.REACT_APP_ENDPOINT_LOCAL,
  local_debug_stage = process.env.REACT_APP_STAGE_SERVER === 'true' ? true : false

let settings = {
  local_debug,
  local_debug_stage,
  endPointServerURL: local_debug ? localEndpoint : prodEndpoint,

  // Header Token App
  keyHeaderAuthToken: "AUTHCODE",
  headerAuthToken:
    "MqrExK4ytYXpperxLq4dndMYtWhrETPSFRC4BaUAetdwZR2TtExwUQV85KyCucZkVnGLzMkS9wgKk6eZtnq9USH5Mg2R7hBv4pRfZGkWMdcNA7n8q7nzEDPwRVQf2fCZRt68MYCbyTUCx7pVDYDGXQHPvy5CZdeAFqjwbd4UQtEcgTzJDpQ58gu6a46LpHHvUKWySUVnwLgGiWVuwX2UMVAN8LiKj62bbN383BXifBWU9dRUzbC7eDbd8EK2BHFMrK59b3rHCSxNp6i3uAv7ytP4HV4N5ATp6WKbtjd62M87",

  // Header Token User App
  keyHeaderDbAuthTokenUser: "AUTHORIZATION",
  //Header Password Key
  keyHeaderPasswordUser: "X-USER-PASSWORD",
  //Header Password Key
  keyHeaderIdUser: "X-ID-USER",
  // Key's maps
  token_access: "APjPQcbMxJKaLtuZQxmUDYDoiucBkHWIJfGpOWNB",
  token_refresh: "RxUbsmQAslOfhfWhUYzRzsQtlUFmDNuNfcYnqjln",
};

export default settings;
