// Styles
import styles from "./ItemToCreditActive.module.css";

export default function ItemToCreditActive(props) {
  let { textLabel, textValue, stylesSpan, styleContainer } = props

  return (
    <>
      <div className={styles.info_complete} style={styleContainer} >
        <p className={styles.label}>{textLabel}</p>
        <span className={styles.value} style={stylesSpan} >{textValue}</span>
      </div>
    </>
  );
}
