import styles from "./DropDownButton.module.css";
// Libraries
import { useHistory } from "react-router-dom";
import { FaHistory, FaPowerOff } from "react-icons/fa";
// Utils
import { creditHistory } from "utils/pathName";

export default function DropDownButton(props) {
  let history = useHistory();

  return (
    <div className={styles.container} >
      <div
        className={styles.item}
        onClick={() => {
          if (history.location.pathname !== creditHistory) {
            history.push(creditHistory);
          }
        }}
        style={{ color: "var(--MEDIUM_BLUE)", marginBottom: "1rem" }}
      >
        <p className={styles.text}>Historial de créditos</p>
        <FaHistory className={styles.icon} />
      </div>
      <div
        className={styles.item}
        onClick={props.setIsModalAlert}
        style={{ color: "var(--COLOR_DISABLED)" }}
      >
        <p className={styles.text}>Cerrar sesión</p>
        <FaPowerOff className={styles.icon} />
      </div>
    </div>
  );
}
