import React from "react";
//styles
import styles from "./ModalAlert.module.css";
//libraries
import { Modal } from "react-bootstrap";
// Components
import TheButton from "../TheButton/TheButton";

export default function ModalAlert(props) {
  let {
    text,
    text2,
    image,
    title,
    valueFee,
    colorTitle,
    colorValue,
    valueAmount,
    isTwoButton,
    setIsModalAlert,
    buttonCloseText,
    buttonCloseClick,
    buttonSuccessText,
    buttonSuccessClick,
    backgroundColorClose,
    backgroundColorSuscces
  } = props;

  return (
    <Modal
      centered
      size="l"
      show={props.isModalAlert}
      onHide={() => { if (setIsModalAlert) setIsModalAlert(!props.isModalAlert) }}
    >
      <Modal.Body className={styles.modal_body}>
        <div className={styles.body}>
          <div className={styles.container_image}>
            {image &&
              <img src={image} alt="Success" className={styles.imageLogo} />
            }
          </div>
          <p className={styles.modal_title} style={{ color: colorTitle }}>{title}</p>
          {valueAmount &&
            <div className={styles.infoCredit}>
              <label className={styles.labelTitle}>Monto
                <p className={styles.labelValue} style={{ color: colorValue }}>{valueAmount}</p>
              </label>

              <label className={styles.labelTitle}>Cuotas
                <p className={styles.labelValue} style={{ color: colorValue }}>{valueFee}</p>
              </label>
            </div>

          }
          <p className={styles.text_info}>{text}</p>
          <p className={styles.modal_title} style={{ color: colorTitle }}>{text2}</p>
          <div className={styles.container_buttons} >
            {isTwoButton && (
              <div
                className={styles.container_button}
                style={{ width: isTwoButton ? "45%" : "100%" }}
              >
                <TheButton
                  nameText={buttonCloseText}
                  GoClick={buttonCloseClick}
                  background={backgroundColorClose}
                />
              </div>
            )}
            <div
              className={styles.container_button}
              style={{ width: isTwoButton ? "45%" : "100%" }}
            >
              <TheButton
                nameText={buttonSuccessText}
                GoClick={buttonSuccessClick}
                background={backgroundColorSuscces}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
