// Components
import TheButton from "components/TheButton/TheButton";
import TheText from "components/TheText/TheText";
// Styles
import styles from "./InfoBasicWork.module.css";
// Icon
import { FaPen } from "react-icons/fa";
// Utils
import { reducedDateFormat } from "utils/dateFormater";
import { creditRetrenchment, newCredit } from "utils/pathName";
// Redux
import { useSelector } from "react-redux";
import cashFormatter from "../../../../../../utils/cashFormatter";

export default function InfoBasicWork(props) {
  let path = window.location.pathname;
  let userDataSignUp = useSelector((state) => state.userSignUpReducer);
  let dataUser = window.location.pathname === newCredit ? props.dataUserAndWork : userDataSignUp;

  return (  
    <div className={styles.Container}>
      <div className={styles.containerInfo}>
        <h2 className={styles.title}>Información básica</h2>
        <br />
        <div className={styles.Divs}>
          <TheText nameText="Nombres" Data={dataUser.first_name} />
          <TheText nameText="Apellidos" Data={dataUser.last_name} />
        </div>
        <div className={styles.Divs}>
          <TheText nameText="Nro. de documento" Data={dataUser.dni} />
          <TheText
            nameText="Fecha de expedición"
            Data={reducedDateFormat(dataUser.date_dni_expedition)}
          />
          <div id={styles.DivResponsive1}>
            <TheText
              nameText="Fecha de Nacimiento"
              Data={reducedDateFormat(dataUser.birthday)}
            />
          </div>
        </div>
        <div className={styles.DivsDifference}>
          <div id={styles.DivResponsive2}>
            <TheText nameText="Nro. de teléfono" Data={dataUser.cellphone} />{" "}
          </div>
          <TheText Data={dataUser.email} nameText="Correo electrónico" />
        </div>
      </div>
      <div className={styles.containerInfo}>
        <h2 className={styles.title}>Información laboral</h2>
        <br />
        <div className={styles.Divs}>
          <TheText
            nameText="Tipo"
            Data={dataUser.type_occupation === 'employee' ? "Empleado" : "Independiente"}
          />
          <TheText
            nameText={
              dataUser.type_occupation === 'employee'
                ? "Nombre de empresa"
                : "Actividad que desempeña"
            }
            Data={dataUser.type_occupation === 'employee' 
              ? dataUser.company 
              : dataUser.activity 
                ? dataUser.activity.title
                : dataUser.job.title
            }
            // widthText={dataUser.type_occupation === 'independent' && '320px' }
            ellipsisText={dataUser.type_occupation === 'independent' ? true : false }
          />
        </div>
        <div className={styles.Divs}>
          <TheText
            nameText="Ingresos mensuales"
            Data={cashFormatter(+dataUser.monthly_income)}
          />
          <TheText
            nameText="Nro. de personas a cargo"
            Data={dataUser.number_people_in_charge}
          />
        </div>
        <div className={styles.containerButton}>
          {(path === newCredit || path === creditRetrenchment) && (
            <TheButton
              IconButton={FaPen}
              background="var(--COLOR_ORANGE)"
              nameText="Actualizar información"
              GoClick={() => props.setModalEditWork(true)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
