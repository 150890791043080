import styles from "./Slider.module.css";
import { Slider } from "@material-ui/core";
import { addComma } from "utils/utils";

export default function SliderFunction(props) {
  let { text, min, max, step, money, noEntra, value, setvalue, defaultValue, isModal } =
    props;

  function handleChange(e, newValue) {
    setvalue(newValue);
  }

  //TEST RAIL
  if (!noEntra) {
    var marks = [
      {
        value: min,
        label: money ? moneytext(addComma(min)) : min,
      },
      {
        value: max,
        label: money ? moneytext(addComma(max)) : max,
      },
    ];
  } else {
    marks = [
      { value: min, label: "" },
      { value: max, label: "" },
    ];
  }

  function moneytext(value) {
    return `$${value} COP`;
  }

  return (
    <>
      <p className={isModal ? styles.textModal : styles.text}>{text}</p>
      <h2 className={isModal ? styles.TextSliderModal : styles.TextSlider}>
        {money ? moneytext(addComma(value)) : value}
      </h2>
      <Slider
        min={min}
        max={max}
        step={step}
        marks={marks}
        value={value}
        track={false}
        valueLabelDisplay="off"
        onChange={handleChange}
        className={styles.SliderL}
        defaultValue={defaultValue}
        getAriaValueText={moneytext}
        aria-labelledby="track-false-ranger-slider"
        style={{ border: "1px solid #CCC", boxSizing: "border-box" }}
      />

      {noEntra === 1 && money ? (
        <div className={isModal ? styles.marksModeModal : styles.marksMode}>
          <p>{moneytext(addComma(min))}</p>
          <p>{moneytext(addComma(max))}</p>
        </div>
      ) : noEntra === 1 ? (
        <div className={isModal ? styles.marksModeModal : styles.marksMode}>
          <p>{addComma(min)}</p>
          <p>{addComma(max)}</p>
        </div>
      ) : (
        ""
      )}
      {noEntra === 2 && money ? (
        <div className={styles.marksMode1}>
          <p>{moneytext(addComma(min))}</p>
          <p>{moneytext(addComma(max))}</p>
        </div>
      ) : noEntra === 2 ? (
        <div className={styles.marksMode2}>
          <p>{addComma(min)}</p>
          <p>{addComma(max)}</p>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
