/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
//styles
import moment from 'moment';
import styles from "./FormBasic.module.css";
//libraries
import * as Yup from "yup";
import { Formik, Form } from "formik";
import ReactTooltip from 'react-tooltip';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaInfoCircle, FaRegEye, FaRegEyeSlash } from "react-icons/fa";
//components
import TheButton from "components/TheButton/TheButton";
import TheSelect from "components/TheSelect/TheSelect";
import TheInputFormik from "components/TheInput/TheInputFormik";
import TheDatePicker from "components/TheDatePicker/TheDatePicker";
import SecurityPassword from "../securityPassword/securityPassword";
import { dateFormater, reducedDateFormat } from "utils/dateFormater";
// utils
import { infoWork } from "utils/pathName";
import { TYPE_DOCUMENTS, GENDERS } from "utils/generalInfo";
import securePasswordFormat from 'utils/securePasswordFormat'
import { capitalizeFirstLeter } from "utils/capitalizeLetter";
import { setDataUserSignUp } from "redux/Actions/ActionsSignUp";
import { setCountries, setStates, setCities } from "redux/Actions/geographyActions";

export const FormBasicTest = () => {
  const history = useHistory();
  let dispatch = useDispatch();
  let userReducer = useSelector((state) => state.userReducer);
  let geography = useSelector((state) => state.geographyReducer);
  let userDataSignUp = useSelector((state) => state.userSignUpReducer);
  // state
  const [showPassword, setShowPassword] = useState(false);
  const [errrosPasswords, setErrorsPassword] = useState([]);
  const [citySelected, setCitySelected] = useState(userDataSignUp.city);
  const [cityDniSelected, setCityDniSelected] = useState(userDataSignUp.city_issuance_dni);
  const [listCitiesDni, setListCitiesDni] = useState([]);
  const [listCitiesHome, setListCitiesHome] = useState([]);
  const [countrySelected, setCountrySelected] = useState(
    userDataSignUp.countryExpedition
  );
  const [departmentSelected, setDepartmentSelected] = useState(
    userDataSignUp.departament
  );
  const [departmentDniSelected, setDepartmentDniSelected] = useState(
    userDataSignUp.departament_issuance_dni
  );
  const [gender, setGender] = useState(
    JSON.stringify(userDataSignUp.genre) !== "{}"
      ? userDataSignUp.genre
      : GENDERS[0]
  );
  const [typeDocument, setTypeDocument] = useState(
    JSON.stringify(userDataSignUp.typeDocument) !== "{}"
      ? userDataSignUp.typeDocument
      : TYPE_DOCUMENTS[0]
  );

  useEffect(() => {
    if (JSON.stringify(countrySelected) === "{}") {
      dispatch(setCountries(setCountrySelected));
    }
    if (JSON.stringify(departmentSelected) === "{}" || !departmentSelected) {
      dispatch(setStates(66, setDepartmentSelected, setCitySelected, setListCitiesHome, true));
    }
    if (JSON.stringify(departmentDniSelected) === "{}" || !departmentDniSelected) {
      dispatch(setStates(66, setDepartmentDniSelected, setCityDniSelected, setListCitiesDni, true));
    }
  }, []);

  useEffect(() => {
    if (JSON.stringify(departmentSelected) !== "{}" && departmentDniSelected) {
      dispatch(
        setCities(
          departmentSelected.value,
          setCitySelected,
          setListCitiesHome
        )
      );
    }
  }, [departmentSelected]);

  useEffect(() => {
    if (JSON.stringify(departmentDniSelected) !== "{}" && departmentDniSelected) {
      dispatch(
        setCities(
          departmentDniSelected.value,
          setCityDniSelected,
          setListCitiesDni
        )
      );
    }
  }, [departmentDniSelected]);

  const validate = Yup.object({
    first_name: Yup.string()
      // .max(15, "Debe tener hasta 20 caracteres o menos")
      .required("Requerido"),
    last_name: Yup.string()
      // .max(20, "Debe tener hasta 20 caracteres o menos")
      .required("Requerido"),
    district: Yup.string()
      .required("Requerido"),
    address: Yup.string()
      // .max(20, "Debe tener hasta 20 caracteres o menos")
      .required("Requerido"),
    dni: Yup.string()
      // .max(20, "Debe tener hasta 20 caracteres o menos")
      .required("Requerido"),
    date_dni_expedition: Yup.date().required("Requerido"),
    birthday: Yup.date().required("Requerido"),
    email: Yup.string()
      .email("Email is invalid")
      .required("Email es requerido")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        "Solo debe contener las letras (a-z), los numeros de (0-9), puntos (.) y guiones (_)"
      ),
    confirmEmail: Yup.string()
      .email("Email is invalid")
      .required("Requerido")
      .oneOf([Yup.ref("email"), null], "los email no coinciden"),
    confirmPassword: Yup.string()
      .required("Requerido")
      .oneOf([Yup.ref("password"), null], "Las contraseñas no coinciden"),
    password: Yup.string()
      .min(8, "La contraseña debe tener mínimo 8 caracteres")
      .matches(/[0-9]/, "La contraseña debe tener al menos un número")
      .matches(
        /[A-Z]/,
        "La contraseña debe tener al menos una mayúscula"
      )
      .required("La contraseña es requerida"),
  });

  let date = new Date()
  let today = new Date()
  date.setFullYear(date.getFullYear() - 18);
  let adult = date

  function tooltip(id_text, text) {
    return (
      <ReactTooltip
        place="top"
        id={`${id_text}`}
        aria-haspopup="true"
      >
        <div className={styles.tooltip}>
          {text}
        </div>
      </ReactTooltip>
    )
  }

  let birthday_sign = userDataSignUp.birthday
  let date_dni_expedition_sign = userDataSignUp.date_dni_expedition

  if (birthday_sign !== '') {
    let new_birthday = new Date(userDataSignUp.birthday)
    let hours_birthday = new_birthday.getHours() + 5
    new_birthday.setHours(hours_birthday)
    let new_date_dni_expedition = new Date(userDataSignUp.date_dni_expedition)
    let hours_date_dni_expedition = new_date_dni_expedition.getHours() + 5
    new_date_dni_expedition.setHours(hours_date_dni_expedition)
    birthday_sign = new Date(new_birthday)
    date_dni_expedition_sign = new Date(new_date_dni_expedition)
  }


  return (
    <Formik
      initialValues={{
        dni: userDataSignUp.dni,
        birthday: birthday_sign,
        email: userDataSignUp.email,
        address: userDataSignUp.address,
        district: userDataSignUp.district,
        last_name: userDataSignUp.last_name,
        first_name: userDataSignUp.first_name,
        confirmEmail: userDataSignUp.confirmEmail,
        date_dni_expedition: date_dni_expedition_sign,

        password: userDataSignUp.password,
        confirmPassword: userDataSignUp.confirmPassword,
      }}
      validationSchema={validate}
      onSubmit={(values) => {
        dispatch(
          setDataUserSignUp({
            ...userDataSignUp,
            dni: values.dni,
            email: values.email,
            address: values.address,
            district: values.district,
            last_name: values.last_name,
            birthday: reducedDateFormat(values.birthday),
            first_name: values.first_name,
            confirmEmail: values.confirmEmail,
            cellphone: userReducer.info_auth.cellphone,
            date_dni_expedition: reducedDateFormat(values.date_dni_expedition),

            password: values.password,
            confirmPassword: values.confirmPassword,

            genre: gender,
            city: citySelected,
            typeDocument: typeDocument,
            departament: departmentSelected,
            countryExpedition: countrySelected,

            city_issuance_dni: cityDniSelected,
            departament_issuance_dni: departmentDniSelected,
          })
        );
        // alert(JSON.stringify(values));
        history.push(infoWork);
      }}
    >
      {(formik) => {
        return (
          <div>
            <Form className={styles.FormContainer}>
              <div className={styles.SeparateDivs}>
                <div className={styles.bigInput}>
                  {tooltip("firstName", "Para una mejor validación de tus datos debes ingresar el nombre completo")}
                  <p className={styles.TextForms} >Nombre completo<FaInfoCircle data-tip data-for="firstName" className={styles.iconInfo} /></p>
                  <TheInputFormik
                    type="text"
                    name="first_name"
                    nameText="Pepito Javier"
                    value={capitalizeFirstLeter(formik.values.first_name, true)}
                  />
                </div>

                <div className={styles.bigInput}>
                  {tooltip("lastName", "Para una mejor validación de tus datos debes ingresar los apellidos completo")}
                  <p className={styles.TextForms}>Apellidos completos<FaInfoCircle data-tip data-for="lastName" className={styles.iconInfo} /></p>
                  <TheInputFormik
                    type="text"
                    name="last_name"
                    nameText="Perez Mateus"
                    value={capitalizeFirstLeter(formik.values.last_name, true)}
                  />
                </div>
              </div>

              <div className={styles.SeparateDivs}>
                <div className={styles.bigInput}>
                  <p className={styles.TextForms}>
                    Fecha de{" "}
                    <strong
                      style={{
                        marginLeft: ".3rem",
                        fontFamily: "var(--FONT_MEDIUM)",
                      }}
                    >
                      nacimiento
                    </strong>{" "}
                  </p>
                  <TheDatePicker
                    name="birthday"
                    nameText="DD/MM/AAAA"
                    date={formik.values.birthday}
                    onChange={formik.setFieldValue}
                    maxDate={adult}
                  />
                </div>

                <div className={styles.bigInput}>
                  <p className={styles.TextForms}>Sexo</p>
                  <TheSelect
                    options={GENDERS}
                    nameText="Selecciona el sexo"
                    checkedOption={gender.label}
                    setCheckedOption={setGender}
                  />
                </div>
              </div>

              {/* <div className={styles.SeparateDivs}>
                <div className={styles.bigInput}>
                  <p className={styles.TextForms}>Tipo de documento</p>
                  <TheSelect
                    options={TYPE_DOCUMENTS}
                    nameText="Cédula de ciudadania"
                    checkedOption={typeDocument.label}
                    setCheckedOption={setTypeDocument}
                  />
                </div>

                <div className={styles.bigInput}>
                  <p className={styles.TextForms}>
                    País de expedición de documento
                  </p>
                  <TheSelect
                    options={geography.countries}
                    nameText="Selecciona un país"
                    checkedOption={countrySelected.label}
                    setCheckedOption={setCountrySelected}
                  />
                </div>
              </div> */}

              <h6 className={styles.blockTitle}>Información de cédula de ciudadanía</h6>

              <div className={styles.SeparateDivs}>
                <div className={styles.bigInput}>
                  <p className={styles.TextForms}>Nro. de documento</p>
                  <TheInputFormik
                    name="dni"
                    type="number"
                    value={formik.values.dni}
                    nameText="Ej. 1093234234"
                    onChange={formik.setFieldValue}
                  />
                </div>
                <div className={styles.bigInput}>
                  <p className={styles.TextForms}>
                    Fecha de{" "}
                    <strong
                      style={{
                        marginLeft: ".3rem",
                        fontFamily: "var(--FONT_MEDIUM)",
                      }}
                    >
                      expedición
                    </strong>{" "}
                  </p>
                  <TheDatePicker
                    maxDate={today}
                    nameText="DD/MM/AAAA"
                    name="date_dni_expedition"
                    onChange={formik.setFieldValue}
                    date={formik.values.date_dni_expedition}
                  />
                </div>
              </div>

              <div className={styles.SeparateDivs}>
                <div className={styles.bigInput}>
                  <p className={styles.TextForms}>Departamento de expedición de documento</p>
                  <TheSelect
                    options={geography.states}
                    nameText="Selecciona un departamento"
                    setCheckedOption={setDepartmentDniSelected}
                    checkedOption={departmentDniSelected && departmentDniSelected.label}
                  />
                </div>

                <div className={styles.bigInput}>
                  <p className={styles.TextForms}>Ciudad de expedición de documento</p>
                  <TheSelect
                    options={listCitiesDni}
                    nameText="Selecciona una ciudad"
                    setCheckedOption={setCityDniSelected}
                    checkedOption={cityDniSelected && cityDniSelected.label}
                  />
                </div>
              </div>

              <h6 className={styles.blockTitle}>Información de domicilio</h6>

              <div className={styles.SeparateDivs}>
                <div className={styles.bigInput}>
                  <p className={styles.TextForms}>Dirección</p>
                  <TheInputFormik
                    type="text"
                    name="address"
                    value={formik.values.address}
                    nameText="Ej. cra 4 #22-33. Pereira"
                  />
                </div>

                <div className={styles.bigInput}>
                  <p className={styles.TextForms}>Barrio</p>
                  <TheInputFormik
                    type="text"
                    name="district"
                    nameText="Ej. Las camelias"
                    value={capitalizeFirstLeter(formik.values.district)}
                  />
                </div>
              </div>

              <div className={styles.SeparateDivs}>
                <div className={styles.bigInput}>
                  <p className={styles.TextForms}>Departamento</p>
                  <TheSelect
                    options={geography.states}
                    nameText="Selecciona un departamento"
                    checkedOption={departmentSelected && departmentSelected.label}
                    setCheckedOption={setDepartmentSelected}
                  />
                </div>

                <div className={styles.bigInput}>
                  <p className={styles.TextForms}>Ciudad</p>
                  <TheSelect
                    options={listCitiesHome}
                    nameText="Selecciona una ciudad"
                    checkedOption={citySelected && citySelected.label}
                    setCheckedOption={setCitySelected}
                  />
                </div>
              </div>

              <h6 className={styles.blockTitle}>Información para inicio de sesión</h6>

              <div className={styles.SeparateDivs}>
                <div className={styles.bigInput}>
                  <p className={styles.TextForms}>Correo electrónico</p>
                  <TheInputFormik
                    name="email"
                    type="email"
                    value={formik.values.email}
                    nameText="Ej: pepitoperez@comosea.com"
                  />
                </div>
                <div className={styles.bigInput}>
                  <p className={styles.TextForms}>
                    <p className={styles.colorText}>Confirmar</p> correo
                    electrónico
                  </p>
                  <TheInputFormik
                    type="email"
                    name="confirmEmail"
                    value={formik.values.confirmEmail}
                    nameText="Ej: pepitoperez@comosea.com"
                  />
                </div>
              </div>

              <div className={styles.SeparateDivs}>
                <div className={styles.bigInput}>
                  {/* {tooltip("password1", "Crea tu contraseña exclusiva para CrediConvenio")} */}
                  {tooltip("password1", "La contraseña para CrediConvenio debe tener mínimo 8 caracteres, una mayúscula, y un número")}
                  <p style={{ color: 'var(--LIGHT_BLUE)' }} className={styles.TextForms}>Contraseña para CrediConvenio<FaInfoCircle data-tip data-for="password1" className={styles.iconInfo} /></p>
                  <TheInputFormik
                    name="password"
                    nameText="Ej:********"
                    value={formik.values.password}
                    type={showPassword ? 'text' : 'password'}
                  />
                  {showPassword ?
                    <FaRegEyeSlash className={styles.iconEye} onClick={() => setShowPassword(!showPassword)} />
                    : <FaRegEye className={styles.iconEye} onClick={() => setShowPassword(!showPassword)} />
                  }
                </div>
                <div className={styles.bigInput}>
                  <p className={styles.TextForms}>
                    <p className={styles.colorText}>Confirmar</p> contraseña
                  </p>
                  <TheInputFormik
                    name="confirmPassword"
                    nameText="Ej:********"
                    value={formik.values.confirmPassword}
                    type={showPassword ? 'text' : 'password'}
                  />
                  {showPassword ?
                    <FaRegEyeSlash className={styles.iconEye} onClick={() => setShowPassword(!showPassword)} />
                    : <FaRegEye className={styles.iconEye} onClick={() => setShowPassword(!showPassword)} />
                  }
                </div>
              </div>

              <div className={styles.container_button}>
                <TheButton
                  nameText="Siguiente"
                  background="var(--MEDIUM_BLUE)"
                />
              </div>
              <br />
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

// Luz Denis Sánchez García
// 42016562
// Fecha de nacimiento : 15-07-1977
// Fecha de expedición : 11-12-1996
