import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "POST";
const SUCCESS_STATUS_CODE = 200;

function postPayCreditLinkGenerate(dataToSend, token) {
  const {dni, idCredit, typePayment, amount} = dataToSend
  const amount_custom_user = typePayment ? `&amount_custom_user=${amount}` : ""
  const endPoint = `/dashboard/api/operative/generate_payment_url?dni=${dni}&id_credit=${idCredit}&type_url=pago_acuerdo${amount_custom_user}`;
  let API_POST_PAY_CREDIT_LINK_GENERATE = endPointAPI + endPoint;
  return APICallback(
    dataToSend,
    API_POST_PAY_CREDIT_LINK_GENERATE,
    METHOD,
    SUCCESS_STATUS_CODE,
    true,
    token,
    false,
    '',
    false,
    '',
  );
}

export { postPayCreditLinkGenerate };
