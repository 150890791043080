export let set_info_user = "set_info_user",
  set_info_credit = "set_info_credit",
  set_info_auth = "set_info_auth";

// geography
export let set_countries = "set_countries",
  set_geography = "set_geography",
  set_cities = "set_cities";

// Data sign up
export let set_data_user_signUp = "set_data_user_sign_up"

// Banks
export let set_list_banks = "set_list_banks"
