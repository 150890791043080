import React, { useState, useEffect, useRef } from "react";
//styles
import styles from "./TheSelect.module.css";
//libraries
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
//utils

export default function TheSelect(props) {
  let { nameText, options, checkedOption, setCheckedOption } = props;
  const refButton = useRef(null);

  const [open, setOpen] = useState(false);
  const [widthButton, setWidthButton] = useState(0);

  useEffect(() => {
    if (refButton.current) setWidthButton(refButton.current.offsetWidth);
  }, [refButton]);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  return (
    <>
      <div className={styles.custom_select}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <div>
            <Button
              disableRipple
              ref={refButton}
              onClick={handleClick}
              className={styles.btn_select}
              endIcon={
                open ? (
                  <FaChevronUp className={styles.icon_select} />
                ) : (
                  <FaChevronDown className={styles.icon_select} />
                )
              }
            >
              {checkedOption ? checkedOption : nameText}
            </Button>
            {open ? (
              <div
                className={styles.options_select}
                style={{
                  width: widthButton,
                }}
              >
                {options.length === 0 ? (
                  <p className={styles.default_text}>No hay más opciones</p>
                ) : (
                  options.map((option) => {
                    return (
                      <p
                        className={styles.option_select}
                        onClick={() => {
                          setCheckedOption(option);
                          handleClickAway();
                        }}
                      >
                        {option.label}
                      </p>
                    );
                  })
                )}
              </div>
            ) : null}
          </div>
        </ClickAwayListener>
      </div>
    </>
  );
}
