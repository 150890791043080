import { useState, useEffect } from "react";
// Styles
import styles from "./header.module.css";
// components
import Account from "../Account";
import Sidebar from "components/sidebar/Sidebar";
import ModalAlert from "../modalAlert/ModalAlert";
import ModalCodeLogin from "../modalsLogin/modalCodeLogin/modalCodeLogin";
import ModalLoginEmail from "components/modalsLogin/modalLoginEmail/modalLoginEmail";
import ModalForgotPassword from "../modalsLogin/modalForgotPassword/modalForgotPassword";
import ModalLoginCellphone from "components/modalsLogin/modalLoginCellphone/modalLoginCellphone";
// Images
import imageConvenios from "images/LogoConvenios.png";
import imageCloseSesion from "images/modal/cerrar_sesion_img.png";
// Libraries
import { Link } from "react-router-dom";
import SideNav from "react-simple-sidenav";
import { useHistory } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
// Utils
import settings from "utils/config";
import { inicio } from "utils/pathName";
// redux
import { useDispatch, useSelector } from "react-redux";
import { setInfoUser, setInfoAuth } from "redux/Actions/Actions";
import DropDownButton from "../dropDownButton/DropDownButton";

export default function Header() {
  const [isSidebar, setIsSidebar] = useState(false);
  const [isModalAlert, setIsModalAlert] = useState(false);
  const [showDropDown, setShowDropdown] = useState(false);
  const [isModalCodeLogin, setModalCodeLogin] = useState(false);
  const [isModalLoginEmail, setModalLoginEmail] = useState(false);
  const [isModalLoginCellphone, setModalLoginCellphone] = useState(false);
  const [isModalForgotPassword, setModalForgotPassword] = useState(false);
  const [windowWidth, setWindowHeight] = useState(window.screen.width);

  const history = useHistory();
  let dispatch = useDispatch();
  let userReducer = useSelector((state) => state.userReducer);

  const controlScrollBody = (bool) => {
    if (!bool) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "auto";
    }
  };

  useEffect(() => {
    controlScrollBody(!isSidebar);
  }, [isSidebar]);

  useEffect(() => {
    function updateSize() {
      setWindowHeight(window.innerWidth);
    }
    window.addEventListener("resize", updateSize);
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  const sesionClose = () => {
    dispatch(
      setInfoUser({
        ...userReducer.info_user,
        dni: "",
        photo: "",
        email: "",
        id_user: "",
        cellphone: 0,
        last_name: "",
        genre_key: "",
        first_name: "",
        genre_label: "",
        type_document: "",
        type_document_label: "",
      })
    );
    dispatch(
      setInfoAuth({
        token_access: "",
        token_refresh: "",
        ...userReducer.info_auth,
        cellphone: 0,
      })
    );
    history.push(inicio);
  };
  return (
    <div className={styles.header}>
      <div className={styles.containerLogo}>
        <Link to={inicio}>
          <img
            className={styles.logo}
            id="logo"
            alt="CREDICONVENIO"
            src={imageConvenios}
          />
        </Link>
      </div>
      <Account
        GoClick={() => {
          if (userReducer.info_user.first_name !== "" && (userReducer[settings.token_access] && userReducer[settings.token_access] !== "")) {
            setShowDropdown(!showDropDown);
          } else {
            setModalLoginCellphone(true);
          }
        }}
      />
      {showDropDown && (
        <DropDownButton
          setIsModalAlert={() => {
            setIsModalAlert(true);
            setShowDropdown(false);
          }}
        />
      )}
      <GiHamburgerMenu
        className={styles.icon_menu}
        onClick={() => {
          if (userReducer.info_user.first_name === "") {
            setModalLoginCellphone(true);
          } else {
            setIsSidebar(true);
          }
        }}
      />
      <ModalAlert
        isTwoButton={true}
        buttonCloseText="Volver"
        image={imageCloseSesion}
        isModalAlert={isModalAlert}
        text="Estás seguro de hacerlo?"
        colorTitle={"var(--COLOR_ALERT)"}
        buttonSuccessText="Cerrar sesión"
        setIsModalAlert={setIsModalAlert}
        title="Estás a punto de cerrar sesión"
        backgroundColorSuscces={"var(--COLOR_DANGER)"}
        backgroundColorClose={"var(--COLOR_DISABLED)"}
        buttonCloseClick={() => setIsModalAlert(false)}
        buttonSuccessClick={() => sesionClose()}
      />
      <ModalLoginCellphone
        isModalLogin={isModalLoginCellphone}
        setModalCodeLogin={setModalCodeLogin}
        setModalLogin={setModalLoginCellphone}
        setModalLoginEmail={setModalLoginEmail}
      />
      <ModalLoginEmail
        isModalLogin={isModalLoginEmail}
        setModalLogin={setModalLoginEmail}
        setModalForgotPassword={setModalForgotPassword}
        setModalLoginCellphone={setModalLoginCellphone}
      />
      <ModalCodeLogin
        isModalCodeLogin={isModalCodeLogin}
        setModalCodeLogin={setModalCodeLogin}
        setModalLoginCellphone={setModalLoginCellphone}
      />
      <ModalForgotPassword
        setModalLoginEmail={setModalLoginEmail}
        isModalForgotPassword={isModalForgotPassword}
        setModalForgotPassword={setModalForgotPassword}
      />
      <SideNav
        openFromRight={false}
        title="Simple Sidenav"
        items={["Item 1", "Item 2"]}
        showNav={isSidebar}
        onHideNav={() => {
          setIsSidebar(!isSidebar);
          controlScrollBody(!isSidebar);
        }}
        children={
          <Sidebar
            setIsSidebar={setIsSidebar}
            setIsModalAlert={setIsModalAlert}
          />
        }
      />
    </div>
  );
}
