import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "POST";
const SUCCESS_STATUS_CODE = 201;

function postSignUp(dataToSend, password, finalPath) {
  const endPoint = `/users/api/signup/${finalPath}`;
  let API_POST_SIGN_UP = endPointAPI + endPoint;
  return APICallback(
    dataToSend,
    API_POST_SIGN_UP,
    METHOD,
    SUCCESS_STATUS_CODE,
    false,
    '',
    false,
    '',
    true,
    password,
  );
}

export { postSignUp };
