import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "GET";
const SUCCESS_STATUS_CODE = 200;

function requestInfoPayments(dataToSend, token) {
  const endPoint = `/credits/api/request/balance/`;
  let API_GET_REQUEST_INFO_PAYMENT = endPointAPI + endPoint;
  return APICallback(
    dataToSend,
    API_GET_REQUEST_INFO_PAYMENT,
    METHOD,
    SUCCESS_STATUS_CODE,
    true,
    token,
    false,
    '',
    false,
    '',
    false,
    '',
  );
}

export { requestInfoPayments };
