import { useState, useEffect } from 'react'
// Styles
import styles from "./Credit_Approved.module.css";
// Libraries
import { useHistory } from "react-router-dom";
// Components
import Loader from 'components/loader/Loader';
import InfoCode from "./components/InfoCode/InfoCode";
import ArrowBack from "components/arrowBack/ArrowBack";
import ModalAlert from "components/modalAlert/ModalAlert";
import InfoCredito from "components/InfoCredito/InfoCredito";
import ImageAndText from "../components/ImageAndText/ImageAndText";
// Images
import imagen from "images/Credit/credito_aprobado.png";
// Utils
import settings from 'utils/config';
import { inicio } from 'utils/pathName';
import cashFormatter from 'utils/cashFormatter';
import { cleanUpDataSignUp } from 'utils/clearDataSignUp';
// REDUX
import { useDispatch, useSelector } from "react-redux";
// API
import { apiRefreshToken } from 'utils/Hooks/useApiRefreshToken';
import { getPromissoryNoteOTP } from 'Api/credit/getPromissoryNoteOTP';

export default function Credit_Approved(props) {
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isModalAlert, setIsModalAlert] = useState(props.location.state.changeCredit);
  const [isModalAlertConfirmCancelCredit, setIsModalAlertConfirmCancelCredit] = useState(false);

  let dispatch = useDispatch();
  let userReducer = useSelector((state) => state.userReducer);
  let userDataSignUp = useSelector((state) => state.userSignUpReducer);

  useEffect(() => {
    if (!props.location.state.trackinLogin) {
      cleanUpDataSignUp(dispatch, userDataSignUp, userReducer)
    }
    if (!props.location.state.changeCredit) {
      apigetPromissoryNoteOTP()
    }
  }, [])

  function apigetPromissoryNoteOTP(token_access) {
    let token = token_access || userReducer[settings.token_access];
    let dataToSend = {};

    setLoading(true);
    setIsModalAlert(false)
    getPromissoryNoteOTP(dataToSend, token).then((response) => {
      setLoading(false);
      console.log(`response promission Note OTP`, response);
      let { data, errored } = response;
      if (errored) {
        if (data.code === "token_not_valid") {
          apiRefreshToken(dispatch, userReducer, history, apigetPromissoryNoteOTP);
        }
      } else {
        return data.success
      }
    }).catch((err) => {
      console.log('err', err)
    })
  }

  return (
    <div className={styles.Container}>
      <Loader loading={loading} />
      <ModalAlert
        isTwoButton={true}
        isModalAlert={isModalAlert}
        backgroundColorClose={isModalAlertConfirmCancelCredit ? "#CDCDCD" : 'red'}
        colorTitle={"var(--MEDIUM_BLUE)"}
        colorValue={"var(--MEDIUM_BLUE)"}
        valueFee={userReducer.info_credit.fee}
        buttonSuccessClick={() => {
          if (isModalAlertConfirmCancelCredit) {
            history.replace(inicio)
          } else {
            apigetPromissoryNoteOTP()
          }
        }}
        backgroundColorSuscces={isModalAlertConfirmCancelCredit ? "#E5B700" : "var(--COLOR_SUCCESS)"}
        title={isModalAlertConfirmCancelCredit
          ? "¿Estas seguro de querer cancelar el crédito?"
          : "¡Tu crédito fue preaprobado!"
        }
        valueAmount={`${cashFormatter(userReducer.info_credit.amount)}`}
        buttonCloseText={isModalAlertConfirmCancelCredit ? "Atras" : "Cancelar"}
        buttonSuccessText={isModalAlertConfirmCancelCredit ? "Aceptar" : "Continuar"}
        buttonCloseClick={() => setIsModalAlertConfirmCancelCredit(!isModalAlertConfirmCancelCredit)}
      />
      <ArrowBack backText="Volver" showLogo={true} onClick={() => history.goBack()} />
      <br />
      <ImageAndText
        image={imagen}
        simpleStyle={false}
        colorText="var(--COLOR_SUCCESS)"
        text={"¡Excelente, tu crédito fue preaprobado!"}
      />
      <div className={styles.container_info_credit}>
        <InfoCredito />
        <InfoCode setLoading={setLoading} apigetPromissoryNoteOTP={apigetPromissoryNoteOTP} />
      </div>
    </div>
  );
}
