import styles from "./TheInput.module.css";

export default function TheInput({
  name,
  error,
  value,
  onBlur,
  onChange,
  nameText,
  helperText,
  type,
}) {
  return (
    <>
      <input
        type={type}
        name={name}
        value={value}
        error={error}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={nameText}
        className={styles.inputcheck}
      />
    </>
  );
}

