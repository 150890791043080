import { set_list_banks } from "../Actions/ActionsName";

let INITIAL_STATE = {
  banks: [],
};

export default function banksReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case set_list_banks: {
      return {
        ...state,
        banks: action.banks,
      };
    }

    default:
      return state;
  }
}
