import { set_info_user, set_info_credit, set_info_auth } from "./ActionsName";

export function setInfoUser(payload) {
  return (dispatch) => {
    dispatch({ type: set_info_user, payload });
  };
}

export function setInfoCredit(payload) {
  return (dispatch) => {
    dispatch({ type: set_info_credit, payload });
  };
}

export function setInfoAuth(payload) {
  return (dispatch) => {
    dispatch({ type: set_info_auth, payload });
  };
}