import { useState, useEffect } from "react";
import { FaInfoCircle, FaSearch } from "react-icons/fa";
import styles from "./Searchbar.module.css";
import activities from "../../utils/activities_code.json";
import { infoWork } from "utils/pathName";

function SearchBar(props) {
  let { placeholder, idJobSelected, setIdJobSelected } = props;
  const [sercher, setSercher] = useState(idJobSelected && idJobSelected !== "NA" ? idJobSelected.title : '');
  const [listActivities, setListActivities] = useState([]);

  useEffect(() => {
    let list_activities = [];
    Object.keys(activities).forEach((activity) => {
      list_activities.push({
        code: activity,
        title: activities[activity],
      });
    });
    setListActivities(list_activities)
  }, [])

  return (
    <div className={styles.search}>
      <div className={styles.searchInputs}>
        <input
          type="text"
          value={sercher}
          className={styles.input}
          placeholder={placeholder}
          onChange={(e) => setSercher(e.target.value)}
        />
        <FaSearch className={styles.searchIcon} />
      </div>
      <div className={styles.dataResult}>
        {listActivities.length !== 0 &&
          <>
            {listActivities.map((job) => {
              return (
                <>
                  {sercher !== "" &&
                    job.title.toLowerCase().indexOf(sercher.toLowerCase()) ===
                    -1 ? null : (
                    <>
                      <p
                        key={job.code}
                        className={
                          job.code === idJobSelected.code
                            ? styles.dataItemSelected
                            : styles.dataItem
                        }
                        onClick={() => {
                          setIdJobSelected(job);
                          setSercher(job.title);
                        }}
                        title={job.title}
                      >
                        - {job.title}
                      </p>
                    </>
                  )}
                </>
              );
            })}
          </>
        }
      </div>
      {window.location.pathname !== infoWork &&
        <p className={styles.info}><FaInfoCircle className={styles.infoIcon} /> Si deseas cambiar de actividad realiza otra busqueda</p>
      }
    </div>
  );
}

export default SearchBar;
