import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "GET";
const SUCCESS_STATUS_CODE = 200;

function getCreditHome(dataToSend, token) {
  const endPoint = `/credits/api/credit/info/home/allied_company/`;
  let API_GET_CREDIT_HOME = endPointAPI + endPoint;
  return APICallback(
    dataToSend,
    API_GET_CREDIT_HOME,
    METHOD,
    SUCCESS_STATUS_CODE,
    true,
    token,
    false,
    '',
    false,
    '',
    false,
    '',
  );
}

export { getCreditHome };
