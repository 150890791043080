import {
  set_cities,
  set_countries,
  set_geography,
} from "../Actions/ActionsName";

let INITIAL_STATE = {
  states: [],
  cities: [],
  countries: []
};

export default function geographyReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case set_countries: {
      return {
        ...state,
        countries: action.countries,
      };
    }

    case set_geography: {
      return {
        ...state,
        states: action.states,
      };
    }

    case set_cities: {
      return {
        ...state,
        cities: action.cities,
      };
    }

    default:
      return state;
  }
}
