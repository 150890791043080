import styles from "./TheButton.module.css";
import {
  HashLoader,
  // ClipLoader, 
  // GridLoader, 
  // FadeLoader, 
  // RingLoader ,
  // PropagateLoader, 
  // ClimbingBoxLoader, 
} from "react-spinners";

export default function TheButton({
  GoClick,
  opacity,
  nameText,
  isLoading,
  background,
  IconButton,
  isAvailable,
  ...props
}) {
  return (
    <>
      <button
        type="submit"
        onClick={GoClick}
        className={styles.buttoncheck}
        disabled={isAvailable || isLoading}
        {...props}
        style={{ background: `${background}`, opacity: `${opacity}` }}
      >
        {
          isLoading
            ? <HashLoader loading={isLoading} color={'white'} size={25} />
            : <>
              {IconButton && <IconButton />} {`${nameText}`}
            </>
        }
      </button>
    </>
  );
}
