import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "GET";
const SUCCESS_STATUS_CODE = 200;

function getCities(dataToSend) {
  const endPoint = `/geography/api/cities/`;
  let GET_CITIES = endPointAPI + endPoint;
  return APICallback(
    dataToSend,
    GET_CITIES,
    METHOD,
    SUCCESS_STATUS_CODE,
    false,
    '',
    false,
    '',
    false,
    '',
    false,
    '',
  );
}

export { getCities };
