import React from "react";
//styles
import styles from "./infodisbur.module.css";
//utils
import image from "../../../../../images/Credit/metodo_de_deposito.png";

export default function Infodisbur() {
  return (
    <div className={styles.Container}>
      <img className={styles.logodisbur} src={image} alt='Desembolso' />
      <h3 className={styles.title}>
        "¡Felicitaciones, el desembolso de tu dinero se realizara con la aprobación de tu empresa!"
      </h3>
      <h5 className={styles.subtitle}>
        Esta es la información asociada al crédito que nos solicitaste
      </h5>
      <br />
    </div>
  );
}
