import React from "react";
//styles
import styles from "./logoDisbur.module.css";
import Logo from "../../../../../images/LogoConvenios.png";

export default function logodisbur() {
  return (
    <div className={styles.logoCrediConvenio}>
      <img src={Logo} alt="disbursement" className={styles.logo} />
    </div>
  );
}
