import React, { useState } from "react";

// Components
import ArrowBack from "components/arrowBack/ArrowBack";
import TabPanel from "../../../../components/TabPanel/TabPanel";
import ImageAndText from "../../components/ImageAndText/ImageAndText";
import InfoCredito from "../../../../components/InfoCredito/InfoCredito.js";
// styles
import styles from "./InfoWork.module.css";
// Images
import logo from "images/Credit/LogoGris.png";
import Image from "images/Credit/Information/informacion_laboral.png";
// Libraries
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { useHistory } from "react-router-dom";
// Redux
import { useDispatch, useSelector } from "react-redux";
// Components
import TheButton from "components/TheButton/TheButton";
import { toast_custom } from "components/toast_custom/toast_custom";
import InfoWorkMoneyPerson from "components/infoWorkMoneyPerson/InfoWorkMoneyPerson";
// Utils
import { dataValidate } from "utils/pathName";
import { setDataUserSignUp } from "redux/Actions/ActionsSignUp";

export default function Information_work() {
  const history = useHistory();
  let dispatch = useDispatch();
  let userDataSignUp = useSelector((state) => state.userSignUpReducer);
  const [companySelected, setCompanySelected] = useState({})
  let userReducer = useSelector((state) => state.userReducer)
  // state
  const [idJobSelected, setIdJobSelected] = useState(userDataSignUp.job);
  const [tabNameEmploye, setTabNameEmploye] = useState(
    userDataSignUp.type_occupation
  );

  const validate = Yup.object({
    monthly_income: Yup.number().required("Requerido"),
    number_people_in_charge: Yup.number().required("Requerido"),
  });

  return (
    <div className={styles.Container}>
      <div className={styles.home}>
        <ArrowBack backText="Volver" onClick={() => history.goBack()} />
        <div className={styles.logoContainer}>
          <img src={logo} alt="CrediConvenio" className={styles.logo} />
        </div>
        <ImageAndText
          image={Image}
          text="Ahora un par de datos acerca de tu información laboral"
        />
        <InfoCredito />
        <Formik
          initialValues={{
            company: userDataSignUp.company,
            monthly_income: userDataSignUp.monthly_income,
            number_people_in_charge: userDataSignUp.number_people_in_charge,
          }}
          validationSchema={validate}
          onSubmit={(values) => {
            dispatch(
              setDataUserSignUp({
                ...userDataSignUp,
                job: idJobSelected,
                company: companySelected.label,
                id_company: companySelected.value,
                type_occupation: tabNameEmploye,
                monthly_income: values.monthly_income,
                number_people_in_charge: values.number_people_in_charge,
              })
            );
            if (tabNameEmploye === 'independent' && idJobSelected === "") {
              toast_custom(
                "Upps, debes seleccionar la actividad que desempeñas",
                "error",
                4000,
                "top-right"
              );
            } else {
              history.push(dataValidate)
            }
          }}
        >
          {(formik) => {
            const { values } = formik;
            return (
              <Form>
                <div className={styles.container_tab}>
                  <TabPanel
                    values={values}
                    formik={formik}
                    idJobSelected={idJobSelected}
                    tabNameEmploye={tabNameEmploye}
                    setIdJobSelected={setIdJobSelected}
                    setTabNameEmploye={setTabNameEmploye}
                    companySelected={companySelected}
                    setCompanySelected={setCompanySelected}
                  />
                  <InfoWorkMoneyPerson values={values} formik={formik} />
                  <div style={{ padding: "1rem 0" }}>
                    <TheButton
                      nameText="Siguiente"
                      background="var(--MEDIUM_BLUE)"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}
