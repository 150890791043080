import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "GET";
const SUCCESS_STATUS_CODE = 200;

function getCountries() {  
  const endPoint = `/geography/api/countries/`;
  let GET_COUNTRIES = endPointAPI + endPoint;
  return APICallback(
    {},
    GET_COUNTRIES,
    METHOD,
    SUCCESS_STATUS_CODE,
    false,
    '',
    false,
    '',
    false,
    '',
    false,
    '',
  );
}

export { getCountries };
