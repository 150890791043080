import styles from "./InfoValidate.module.css";
// Images
import LogoCrediConvenio from "images/LogoConvenios.png";
import imagen from "images/Credit/Information/datos_recolectados.png";
// Utils
import { newCredit, dataValidate } from "utils/pathName";
// Libraries
import { useSelector } from "react-redux";

export default function InfoValidate() {
  let userReducer = useSelector((state) => state.userReducer);
  let userDataSignUp = useSelector((state) => state.userSignUpReducer);

  let nameUser =
    userDataSignUp.first_name !== ""
      ? userDataSignUp.first_name
      : userReducer.info_user.first_name;

  return (
    <div className={styles.Container}>
      {window.location.pathname === dataValidate ? (
        <div className={styles.containerImg}>
          <img
            src={LogoCrediConvenio}
            alt="Logo CrediConvenio"
            className={styles.LogoUno}
          />
        </div>
      ) : window.location.pathname === newCredit ? (
        <p className={styles.titleInfo}>Solicitar nuevo credito</p>
      ) : (
        <p className={styles.titleInfo}>Solicitar retanqueo</p>
      )}
      <img className={styles.Logo} src={imagen} alt="Ya casi esta listo" />
      <p className={styles.TextColor}>¡Hola, {nameUser}!</p>
      <p className={styles.textInfo}>
        A continuación veras toda la información que ingresaste con el fin de
        validar que todo este en orden
      </p>
    </div>
  );
}
