//styles
import styles from "./ImageInfoLogin.module.css";

const ImageInfoLogin = (props) => {
  return (
    <>
      <img
        alt="crediconvenio"
        className={styles.coffe_cup_login}
        src={props.image}
      />
      <p className={styles.title}>
        Tomate un café mientras solicitas tu credito...
        <br /> No tardaremos mucho
      </p>
      <img
        alt="crediconvenio"
        className={styles.logo_login}
        src="images/components/logo_login.png"
      />
    </>
  );
};

export default ImageInfoLogin;
