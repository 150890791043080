import styles from "./TheInput.module.css";
import NumberFormat from "react-number-format";
import { ErrorMessage, useField } from "formik";

export default function TheInput({ nameText, ...props }) {
  const [field, meta] = useField(props);
  return (
    <div className={styles.Container}>
      {props.type === "number" ? (
        <NumberFormat
          {...props}
          format={props.format}
          placeholder={nameText}
          type={props.typeNumberFormat}
          onValueChange={(values) => {
            let { value } = values;
            props.onChange(props.name, value);
          }}
          className={`${styles.inputcheck} ${
            meta.touched && meta.error && "is-invalid"
          }`}
        />
      ) : (
        <input
          autoComplete="off"
          type={props.type}
          {...field}
          {...props}
          placeholder={nameText}
          className={`${styles.inputcheck} ${
            meta.touched && meta.error && "is-invalid"
          }`}
        />
      )}
      <ErrorMessage
        className={styles.error}
        component="div"
        name={field.name}
      />
    </div>
  );
}
