import settings from "../../utils/config";
import {
  set_info_user,
  set_info_credit,
  set_info_auth
} from "../Actions/ActionsName";

let INITIAL_STATE = {
  info_user: {
    dni: "",
    photo: "",
    email: "",
    id_user: -1,
    last_name: "",
    cellphone: "",
    genre_key: "",
    first_name: "",
    genre_label: "",
    type_document: "",
    type_document_label: "",
    modules: []
  },

  info_auth: {
    cellphone: '',
  },

  info_credit: {
    fee: 0,
    vat: 0,
    amount: 0,
    id_credit: -1,
    monthlyCost: 0,
    endorsement: 0,
    central_risk: 0,
    interest_rate: 0,
    annual_interest_rate: 0,
    list_values_credit_study_per_month: []
  },
};

INITIAL_STATE[settings.token_access] = "";
INITIAL_STATE[settings.token_refresh] = "";

export default function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case set_info_user: {
      return {
        ...state,
        info_user: {
          dni: action.payload.dni,
          photo: action.payload.photo,
          email: action.payload.email,
          id_user: action.payload.id_user,
          last_name: action.payload.last_name,
          genre_key: action.payload.genre_key,
          cellphone: action.payload.cellphone,
          first_name: action.payload.first_name,
          genre_label: action.payload.genre_label,
          type_document: action.payload.type_document,
          type_document_label: action.payload.type_document_label,
          modules: action.payload.modules,
        },
      };
    }

    case set_info_credit: {
      return {
        ...state,
        info_credit: {
          fee: action.payload.fee,
          vat: action.payload.vat,
          amount: action.payload.amount,
          id_credit: action.payload.id_credit,
          monthlyCost: action.payload.monthlyCost,
          endorsement: action.payload.endorsement,
          central_risk: action.payload.central_risk,
          interest_rate: action.payload.interest_rate,
          annual_interest_rate: action.payload.annual_interest_rate,
          list_values_credit_study_per_month: action.payload.list_values_credit_study_per_month,
        },
      };
    }

    case set_info_auth: {
      state[settings.token_access] = action.payload.token_access;
      state[settings.token_refresh] = action.payload.token_refresh;
      return {
        ...state,
        info_auth: {
          cellphone: action.payload.cellphone,
        },
      };
    }

    default:
      return state;
  }
}
