import { useState } from "react";
//styles
import styles from "./modalCodeLogin.module.css";
//libraries
import * as Yup from "yup";
import { Formik, Form } from "formik";
import Modal from "@material-ui/core/Modal";
import { useHistory } from "react-router-dom";
import { FaArrowLeft, FaTimes } from "react-icons/fa";
// redux
import { useDispatch, useSelector } from "react-redux";
import { setInfoUser, setInfoAuth } from "redux/Actions/Actions";
//Components
import TheButton from "components/TheButton/TheButton";
import TheInput from "components/TheInput/TheInputFormik";
import { toast_custom } from "components/toast_custom/toast_custom";
import CountDownTimer from "components/countDownTimer/countDownTimer";
import ImageInfoLogin from "../components/imageInfoLogin/ImageInfoLogin";
// utils
import { inicio } from "utils/pathName";
import Asterisco from "components/Asterisco/Asterisco";
// Api
import { postCodeLogin } from "Api/login/postCodeLogin";

const ModalCodeLogin = (props) => {
  let dispatch = useDispatch();
  let userReducer = useSelector((state) => state.userReducer);
  const minSecs = { minutes: 2, seconds: 0 };
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const history = useHistory();

  const apiPostCodeLogin = (UID) => {
    const cellphoneToSend = `+57${userReducer.info_auth.cellphone}`;
    const dataToSend = {
      otp_code: UID,
      verify_user: true,
      cellphone: cellphoneToSend,
    };

    postCodeLogin(dataToSend)
      .then((response) => {
        setLoading(false)
        console.log(`response code login`, response);
        const { data, errored } = response;
        if (errored) {
          switch (data.err_code) {
            default:
              toast_custom(
                "Upss, Estamos presentando problemas",
                "error",
                4000,
                "top-right"
              );
              break;
          }
        } else {
          dispatch(
            setInfoUser({
              ...userReducer.info_user,
              dni: data.data_login.user_data.dni,
              photo: data.data_login.user_data.photo,
              email: data.data_login.user_data.email,
              id_user: data.data_login.user_data.id_user,
              last_name: data.data_login.user_data.last_name,
              cellphone: +data.data_login.user_data.cellphone,
              genre_key: data.data_login.user_data.genre_key,
              first_name: data.data_login.user_data.first_name,
              genre_label: data.data_login.user_data.genre_label,
              type_document: data.data_login.user_data.type_document,
              type_document_label:
                data.data_login.user_data.type_document_label,
            })
          );
          dispatch(
            setInfoAuth({
              token_access: data.data_login.tokens.access,
              token_refresh: data.data_login.tokens.refresh,
              ...userReducer.info_auth,
              cellphone: +data.data_login.user_data.cellphone,
            })
          );
          history.push(inicio);
        }
      })
      .catch((err) => {
        console.log(err)
        setLoading(false)
        toast_custom(
          "Upps, Estamos presentando problemas",
          "error",
          4000,
          "top-right"
        );
      });
  };

  return (
    <Modal
      open={props.isModalCodeLogin}
      onClose={() => props.setModalCodeLogin(false)}
    >
      <div className={styles.principal_container}>
        <Formik
          initialValues={{
            Code: "",
          }}
          validationSchema={Yup.object().shape({
            Code: Yup.string().test(
              "len",
              "El código debe tener 6 números",
              (val) => `${val}`.length === 6
            ),
          })}
          onSubmit={(values) => apiPostCodeLogin(values.Code)}
        >
          {(formik) => {
            const { values } = formik;
            return (
              <div className={styles.content_modal}>
                <FaArrowLeft
                  className={styles.arrow_icon}
                  onClick={() => {
                    props.setModalCodeLogin(false);
                    props.setModalLoginCellphone(true);
                  }}
                />
                <div className={styles.section_info}>
                  <ImageInfoLogin image="images/components/coffe_cup_login.png" />
                </div>
                <div className={styles.form_login}>
                  <FaTimes
                    className={styles.close_icon}
                    onClick={() =>
                      props.setModalCodeLogin(!props.isModalCodeLogin)
                    }
                  />
                  <p className={styles.login_title}>Iniciar sesión</p>
                  <p className={styles.subtitle}>
                    Muy bien, ahora solo tienes que ingresar el código que
                    enviamos a través de mensaje de texto (SMS) al número que
                    nos diste
                  </p>
                  <Form className={styles.form}>
                    <div className={styles.input}>
                      <p className={styles.label}>
                        Código de ingreso
                        <Asterisco />
                      </p>
                      <TheInput
                        name="Code"
                        type="number"
                        value={values.Code}
                        format="# # # # # #"
                        nameText="Ingresa el código aquí"
                        onChange={formik.setFieldValue}
                      />
                      <div style={{ marginTop: "1rem", width: "100%" }}>
                        <TheButton
                          nameText="Ingresar"
                          isLoading={loading}
                          background={"var(--MEDIUM_BLUE)"}
                        />
                      </div>
                    </div>
                  </Form>
                  <p className={styles.text_countdown}>
                    Cuenta atrás solicitar un nuevo código en:
                  </p>
                  <p className={styles.countdown}>
                    <CountDownTimer
                      minSecs={minSecs}
                      setIsDisabled={setIsDisabled}
                    />
                  </p>
                  <p className={styles.not_code}>¿No te llega el código?</p>
                  <p
                    className={styles.request_again}
                    style={{ color: isDisabled && "var(--COLOR_DISABLED)" }}
                    onClick={() => {
                      if (!isDisabled) {
                        props.setModalCodeLogin(false);
                        props.setModalLoginCellphone(true);
                      }
                    }}
                  >
                    Solicitar nuevamente
                  </p>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default ModalCodeLogin;