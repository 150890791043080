// Styles
import { Link } from "react-router-dom";
import styles from "./ButtonHome.module.css";

export default function ButtonHome(props) {
  return (
    <>
      {props.link ? (
        <Link 
          to={props.to}
          className={styles.container}
          style={{ background: `var(--${props.background})` }}
        >
          <props.icon className={styles.icon} />
          <p className={styles.text}>{props.text}</p>
        </Link>
      ) : (
        <div
          onClick={props.onClick}
          className={styles.container}
          style={{ 
            background: `var(--${props.background})`, 
            opacity: props.opacity && props.opacity 
          }}
        >
          <props.icon className={styles.icon} />
          <p className={styles.text}>{props.text}</p>
        </div>
      )}
    </>
  );
}
