import settings from '../../utils/config';
import APICallback from '../../utils/APICallback';
let endPointAPI = settings.endPointServerURL;
const METHOD = "POST";
const SUCCESS_STATUS_CODE = 200;

function postPayCredit(dataToSend, token) {
  const endPoint = `/payvalida/api/cash/in/`;
  let API_POST_PAY_CREDIT = endPointAPI + endPoint;
  return APICallback(
    dataToSend,
    API_POST_PAY_CREDIT,
    METHOD,
    SUCCESS_STATUS_CODE,
    true,
    token,
    false,
    '',
    false,
    '',
  );
}

export { postPayCredit };
