import { useState, useEffect } from "react";
// Styles
import styles from "./History.module.css";
// Libraries
import { useHistory } from "react-router-dom";
import { FaChevronRight } from "react-icons/fa";
//Components
import Header from "components/Header";
import Title from "./components/title/title";
import Loader from "../../../components/loader/Loader";
import ModalDetailCredit from "./components/modalDetailCredit/modalDetailCredit";
// Utils
import settings from "../../../utils/config";
import { getCredit } from "Api/home/getCredit";
import cashFormatter from "utils/cashFormatter";
import { getListCredits } from "Api/history/getListCredit";
import { apiRefreshToken } from "utils/Hooks/useApiRefreshToken";
// Redux
import { useDispatch, useSelector } from "react-redux";
// Images
import imageDefault from "images/home/credito_default.png"

export default function Credit_History() {
  const history = useHistory();
  let dispatch = useDispatch();
  let userReducer = useSelector((state) => state.userReducer);
  // State
  const [credits, setCredits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCredit, setSelectedCredit] = useState(null);
  const [isModalDetailCredit, setModalDetailCredit] = useState(false);

  useEffect(() => {
    apiGetListCredits();
  }, []);

  const apiGetListCredits = (token_access) => {
    setLoading(true);
    let token = token_access || userReducer[settings.token_access];
    getListCredits({}, token).then((response) => {
      console.log(`response list credits`, response);
      let { data, errored } = response;
      if (errored) {
        if (data.code === "token_not_valid") {
          apiRefreshToken(dispatch, userReducer, history, apiGetListCredits);
        }
      } else {
        setLoading(false);
        setCredits(data.credits);
      }
    });
  };

  const apiGetdetailCredit = (id_credit) => {
    setLoading(true);
    let token = userReducer[settings.token_access];
    let dataToSend = {
      id_credit,
    };
    getCredit(dataToSend, token).then((response) => {
      setLoading(false);
      console.log(`response detail credit`, response);
      let { data, errored } = response;
      if (errored) {
        if (data.code === "token_not_valid") {
          apiRefreshToken(dispatch, userReducer, history, apiGetListCredits);
        }
      } else {
        setSelectedCredit(data.credit);
        setModalDetailCredit(true);
      }
    });
  };

  return (
    <>
      {selectedCredit && (
        <ModalDetailCredit
          selectedCredit={selectedCredit}
          isModalDetailCredit={isModalDetailCredit}
          setModalDetailCredit={setModalDetailCredit}
        />
      )}
      <Header />
      <Loader loading={loading} />
      <div className={styles.principal_container}>
        <Title title="Historial de créditos" click={() => history.goBack()} />
        {/* history cards */}
        {credits.length > 0 ? (
          <div className={styles.container_cards}>
            {credits.map((credit) => {
              return (
                <div
                  key={credit.id_credit}
                  className={styles.card_history}
                  onClick={() => {
                    // setSelectedCredit(credit);
                    apiGetdetailCredit(credit.id_credit);
                  }}
                >
                  <div className={styles.info_card}>
                    <p className={styles.title_card}>
                      Crédito Nro. {credit.id_credit}
                    </p>
                    <div className={styles.container_value}>
                      <p className={styles.label}>Valor: </p>
                      <p className={styles.value}>
                        {" "}
                        {cashFormatter(credit.acquired_debt)}
                      </p>
                    </div>
                    <div className={styles.container_value}>
                      <p className={styles.label}>Estado: </p>
                      <p className={styles.paid}
                        style={{
                          color:
                            credit.state === "FIN"
                              ? "var(--COLOR_SUCCESS)"
                              : "var(--MEDIUM_BLUE)",
                        }}
                      >
                        {credit.state === "FIN" ? "Pagado" : "Vigente"}</p>
                    </div>
                  </div>
                  <FaChevronRight className={styles.right_chevron_icon} />
                </div>
              );
            })}
          </div>
        )
          :
          (<div className={styles.containerDefault}>
            <img src={imageDefault} alt="Lista de créditos vacia" />
          </div>
          )
        }
      </div>
    </>
  );
}
