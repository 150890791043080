import React, { useEffect, useState } from 'react'
// Styles
import styles from './ConfirmCreditToUser.module.css';
// Images
import creditConfirmImage from "../../images/Credit/confirmCreditTouser.png";
// Components
import TheButton from 'components/TheButton/TheButton';
// Api
import Loader from 'components/loader/Loader';
import { getDataSimulator } from 'Api/getDataSimulator';
import { toast_custom } from 'components/toast_custom/toast_custom';
import { postConfirmCreditfromEmployee } from 'Api/credit/postConfirmCreditFromEmployee';
// Libraries
import { useParams, useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
// Utils
import { inicio } from 'utils/pathName';
import cashFormatter from 'utils/cashFormatter';
import simulatorCredit from 'utils/creditSimulator';

const ConfirmCreditToUser = () => {
  const colorImageBlue = 'brightness(0) saturate(100%) invert(43%) sepia(11%) saturate(3224%) hue-rotate(165deg) brightness(87%) contrast(103%)'
  const colorImageRed = 'brightness(0) saturate(100%) invert(30%) sepia(6%) saturate(3224%) hue-rotate(-57deg) brightness(118%) contrast(579%)'
  const colorImageGreen = 'brightness(0) saturate(100%) invert(43%) sepia(11%) saturate(3224%) hue-rotate(464deg) brightness(150%) contrast(85%)'
  const [imageColor, setImageColor] = useState(colorImageBlue);

  const [loading, setLoading] = useState(false)
  const [dataSimulatorCredit, setDataSimulatorCredit] = useState({})

  const { id_credit } = useParams();
  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const installments = queryParams.get('installments');
  const requested_amount = queryParams.get('requested_amount');

  const handleHover = (color) => {
    setImageColor(color);
  };

  const handleLeave = () => {
    setImageColor(colorImageBlue);
  };

  useEffect(() => {
    apiGetDataSimulator()
  }, [])

  const apiGetDataSimulator = () => {
    setLoading(true)
    let dataToSend = { type_dash: process.env.REACT_APP_TYPE_USER }
    getDataSimulator(dataToSend).then((response) => {
      setLoading(false)
      console.log(`response`, response)
      let { data, errored } = response
      if (errored) {
      } else {
        let responseSimulatorCredit = simulatorCredit(
          +installments,
          parseFloat(data.ms_configuration_simulator.vat),
          parseFloat(data.ms_configuration_simulator.endorsement),
          parseFloat(data.ms_configuration_simulator.central_risk),
          parseFloat(data.ms_configuration_simulator.interest_rate),
          +requested_amount,
          data.ms_configuration_simulator.list_values_credit_study_per_month,
          true
        )
        setDataSimulatorCredit(responseSimulatorCredit)
      }
    })
  }

  function apiPostConfirmCreditfromEmployee(action) {
    const dataToSend = {
      action,
      credit_id: id_credit,
      installments_updated: installments,
      requested_amount_updated: requested_amount,
    }

    setLoading(true);
    postConfirmCreditfromEmployee(dataToSend)
      .then((response) => {
        const { data, errored } = response;
        if (errored) {
          toast_custom(
            "Error al confirmar el estado del crédito",
            "error",
            4000,
            "top-right"
          ); return
        } else {
          toast_custom(
            "Se envio la confirmación del credito",
            "success",
            4000,
            "top-right"
          );
          setTimeout(() => {
            history.push(inicio)
          }, 2000);
        }
      })
      .catch(err => console.log)
      .finally(() => setLoading(false))
  }

  return (
    <div className={styles.main}>
      <Loader loading={loading} />
      <h2 className={styles.title} >Confirmar tu crédito</h2>
      <p>Tu empresa aprobó tu crédito, pero ha cambiado algunos valores </p>
      <div className={styles.creditInfo}>
        <p>Saldo aprobado <br /> <span>{cashFormatter(requested_amount)}</span></p>
        <p>Cuotas <br /> <span>{installments}</span></p>
        <p>Deuda adquirida <br /> <span>{cashFormatter(dataSimulatorCredit.total)}</span></p>
        <p>Cuota quincenal <br /> <span>{cashFormatter(dataSimulatorCredit.monthlyCost / 2)}</span></p>
      </div>
      <img
        alt="Confirmar crédito"
        src={creditConfirmImage}
        className={styles.image}
        style={{ filter: `${imageColor}` }}
      />

      <div className={styles.container_buttons}>
        <TheButton
          nameText={'Rechazar'}
          GoClick={() => apiPostConfirmCreditfromEmployee('reject')}
          background='var(--COLOR_RED)'
          onMouseEnter={() => handleHover(colorImageRed)} onMouseLeave={handleLeave}
        />
        <TheButton
          nameText={'Confirmar'}
          GoClick={() => apiPostConfirmCreditfromEmployee('acept')}
          background='var(--MEDIUM_BLUE)'
          onMouseEnter={() => handleHover(colorImageGreen)} onMouseLeave={handleLeave}
        />
      </div>
    </div>
  )
}

export default ConfirmCreditToUser