//styles
import styles from "./modalForgotPassword.module.css";
//libraries
import * as Yup from "yup";
import Modal from "@material-ui/core/Modal";
import { FaArrowLeft, FaTimes } from "react-icons/fa";
import { Formik, Form, ErrorMessage } from "formik";
//Components
import TheInput from "components/TheInput/TheInput";
import TheButton from "components/TheButton/TheButton";
import ImageInfoLogin from "../components/imageInfoLogin/ImageInfoLogin";
import { toast_custom } from "components/toast_custom/toast_custom";
// Api
import { postForgotPassword } from "Api/login/postForgotPassword";

const ModalForgotPassword = (props) => {
  const apiPostForgotPassword = (email) => {
    let dataToSend = {
      email: email.toLowerCase(),
    };
    postForgotPassword(dataToSend)
      .then((response) => {
        console.log(`response forgot password`, response);
        let { data, errored } = response;
        if (errored) {
          switch (data.err_code) {
            case "USER_NOT_FOUND":
              toast_custom(
                "No podemos enviar una solicitud de recuperar contraseña a este correo",
                "error",
                4000,
                "top-right"
              );
              break;
            default:
              toast_custom(
                "Upss, Estamos presentando problemas",
                "error",
                4000,
                "top-right"
              );
              break;
          }
        } else {
          toast_custom(
            "Te enviamos al correo los pasos para recuperar tu contraseña",
            "success",
            5000,
            "top-right"
          );
          props.setModalForgotPassword(false)
        }
      })
      .catch((err) => {
        toast_custom(
          "Upps, Estamos presentando problemas",
          "error",
          4000,
          "top-right"
        );
      });
  };

  return (
    <Modal
      open={props.isModalForgotPassword}
      onClose={() => props.setModalForgotPassword(false)}
    >
      <div className={styles.principal_container}>
        <Formik
          initialValues={{
            email: "",
          }}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Ingresa un correo válido")
              .required("El correo es requerido")
              .matches(
                /^[a-zA-Z0-9._-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                "Solo debe contener las letras (a-z), los numeros de (0-9) y puntos (.)"
              ),
          })}
          onSubmit={(values) => {
            apiPostForgotPassword(values.email);
          }}
        >
          {(formik) => {
            const { values } = formik;
            return (
              <div className={styles.content_modal}>
                <FaArrowLeft
                  className={styles.arrow_icon}
                  onClick={() => {
                    props.setModalForgotPassword(false);
                    props.setModalLoginEmail(true);
                  }}
                />
                <div className={styles.section_info}>
                  <ImageInfoLogin image="images/components/recuperar_contraseña.png" />
                </div>
                <div className={styles.form_forgot_password}>
                  <FaTimes
                    className={styles.close_icon}
                    onClick={() =>
                      props.setModalForgotPassword(!props.isModalForgotPassword)
                    }
                  />
                  <p className={styles.login_title}>
                    ¿Olvidaste tu contraseña?
                  </p>
                  <p className={styles.subtitle}>
                    No te preocupes, solo ingresa tu correo electrónico y te
                    enviaremos un enlace para que puedas recuperarla
                  </p>
                  <Form className={styles.form}>
                    <div className={styles.input}>
                      <p className={styles.label}>Correo electrónico*</p>
                      <TheInput
                        name="email"
                        widthText="90%"
                        value={values.email}
                        nameText="Ej. mario@crediconvenio.com"
                        onChange={formik.handleChange}
                      />
                      <ErrorMessage
                        name="email"
                        component="span"
                        className={styles.label_error}
                      />
                      <div style={{ marginTop: "1rem", width: "100%" }}>
                        <TheButton
                          nameText="Solicitar correo"
                          background={"var(--MEDIUM_BLUE)"}
                        />
                      </div>
                    </div>
                  </Form>
                  <p
                    onClick={() => {
                      props.setModalForgotPassword(false);
                      props.setModalLoginEmail(true);
                    }}
                    className={styles.on_back}
                  >
                    Volver
                  </p>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default ModalForgotPassword;
