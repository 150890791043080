import { approveCredit, confirmDisbursement, historyCredits, historyDC, latePayments } from "./pathName";

export const TYPE_DOCUMENTS = [
  {
    code: 13,
    name: "C.C.",
    label: "Cedula de ciudadania",
  },
  {
    code: 31,
    name: "NIT",
    label: "Nit",
  },
  {
    code: 41,
    name: "PASAPORTE",
    label: "Pasaporte",
  },
  {
    code: 12,
    name: "TI",
    label: "Tarjeta de identidad",
  },
  {
    code: 21,
    name: "TI",
    label: "Tarjeta de extranjería",
  },
  {
    code: 22,
    name: "C.E",
    label: "Cédula de extranjería",
  },
  {
    code: 11,
    name: "R.C.N",
    label: "Registro civil de nacimiento",
  },
  {
    code: 42,
    name: "T.D.E",
    label: "Tipo de documento extranjero",
  },
  {
    code: 43,
    name: "O.D",
    label:
      "Sin identificación del exterior o para uso definido por la DIAN. Cuantías menores",
  },
];

export const GENDERS = [
  {
    value: "female",
    label: "Femenino",
  },
  {
    value: "male",
    label: "Masculino",
  },
  {
    value: "otro",
    label: "Otro",
  },
];