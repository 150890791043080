export function capitalizeFirstLeter(str, isForWord) {
  if(isForWord){
    // Uppercase for the first letter for each word with regex but don't work with accents (tildes)
    // return str.replace(/\b\w/g, function (leter) { return leter.toUpperCase() })
    
    // Uppercase for the first letter for each word
    return str.split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ')
  } else {
    // Uppercase only for the first letter for all text
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}